import { render, staticRenderFns } from "./RewardsCart.vue?vue&type=template&id=46ef1edb&scoped=true&"
import script from "./RewardsCart.vue?vue&type=script&lang=js&"
export * from "./RewardsCart.vue?vue&type=script&lang=js&"
import style0 from "./RewardsCart.vue?vue&type=style&index=0&id=46ef1edb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ef1edb",
  null
  
)

export default component.exports