<template>
  <no-ssr>
    <div class="main">
      <article class="pro_checkout">
        <section id="cart" class="cart-section relative">
          <div class="pro_checkout_container relative">
            <h2 class="pro_checkout_sp block font-serif font-black text-center text-h2 leading-none text-dark capitalize mb-6 md:hidden">
              {{ $t('Your cart') }}
            </h2>
            <div v-if="productsInCart.length">
              <div id="rco"
                   class="pro_checkout_brand md:pr-15 rco"
                   v-if="groupedProducts.rco && groupedProducts.rco.length">
                <div class="pro_checkout_brandbar pb-5">
                  <i class="icon-rco text-caption"></i>
                </div>
                <product :products-in-cart="groupedProducts.rco"/>
                <div class="checkout_brand_subtotal m-0 text-right relative">
                  <div class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('R+CO SUBTOTAL:')}}
                    </span>
                    <span class="price text-md leading-none tracking-thick md:absolute text-left md:text-h3">
                      <span class="block mb-2">{{ brandSubtotal.rco | price }}</span>
                      <span v-if="brandLoyaltySubtotal.rco" class="block mb-2">+ <span>&diams; </span>{{ (brandLoyaltySubtotal.rco || 0).toFixed(2) }} {{ $t('Pts') }}</span>
                    </span>
                  </div>
                  <div v-if="points['rco'] && points['rco']['earnedPoints']" class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('R+CO POINTS EARNED*:')}}
                    </span>
                    <span class="pro_checkout_pts absolute p-0">
                      <span
                        class="pro_pt_type_totals flex justify-between text-left font-black text-h5 leading-h3 tracking-md uppercase bg-green text-white px-2 relative"
                        :style="{ backgroundColor: `#${points['rco']['color']}` }"
                      >
                        <span class="pro_pt_amt">
                          {{ points['rco']['earnedPoints'].toFixed(2) }}
                        </span>
                        <span class="pro_pt_type">
                          {{ $t('points') }}
                        </span>
                      </span>

                    </span>
                  </div>
                  <div
                    v-if="points['rco'] && points['rco']['earnedPoints']"
                    class="text-sm flex justify-end align-right mx-10 my-2">
                    *Final earning amount is determined at time of shipment.</div>
                </div>
              </div>
              <div id="rco_bleu"
                   class="pro_checkout_brand md:pr-15 rco"
                   v-if="groupedProducts.rco_bleu && groupedProducts.rco_bleu.length">
                <div class="pro_checkout_brandbar pb-5">
                  <i class="icon-rco-bleu text-caption"></i>
                </div>
                <product :products-in-cart="groupedProducts.rco_bleu"/>
                <div class="checkout_brand_subtotal m-0 text-right relative">
                  <div class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('R+CO BLEU SUBTOTAL:')}}
                    </span>
                    <span class="price text-md leading-none tracking-thick md:absolute text-left md:text-h3">
                      <span class="block mb-2">{{ brandSubtotal.rco_bleu | price }}</span>
                      <span v-if="brandLoyaltySubtotal.rco_bleu" class="block mb-2">+ <span>&diams; </span>{{ (brandLoyaltySubtotal.rco_bleu || 0).toFixed(2) }} {{ $t('Pts') }}</span>
                    </span>
                  </div>
                  <div v-if="points['rco'] && points['rco']['earnedPoints']" class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('R+CO POINTS EARNED:')}}
                    </span>
                    <span class="pro_checkout_pts absolute p-0">
                      <span
                        class="pro_pt_type_totals flex justify-between text-left font-black text-h5 leading-h3 tracking-md uppercase bg-green text-white px-2 relative"
                        :style="{ backgroundColor: `#${points['rco']['color']}` }"
                      >
                        <span class="pro_pt_amt">
                          {{ points['rco']['earnedPoints'].toFixed(2) }}
                        </span>
                        <span class="pro_pt_type">
                          {{ $t('points') }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div
                    v-if="points['rco'] && points['rco']['earnedPoints']"
                    class="text-sm flex justify-end align-right mx-10 my-2">
                    *Final earning amount is determined at time of shipment.</div>
                </div>
              </div>
              <div id="rco_color"
                   class="pro_checkout_brand md:pr-15 rco"
                   v-if="groupedProducts.rco_color && groupedProducts.rco_color.length">
                <div class="pro_checkout_brandbar pb-5">
                  <i class="icon-rco-color text-caption"></i>
                </div>
                <product :products-in-cart="groupedProducts.rco_color"/>
                <div class="checkout_brand_subtotal m-0 text-right relative">
                  <div class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('R+COLOR SUBTOTAL:')}}
                    </span>
                    <span class="price text-md leading-none tracking-thick md:absolute text-left md:text-h3">
                      <span class="block mb-2">{{ brandSubtotal.rco_color | price }}</span>
                      <span v-if="brandLoyaltySubtotal.rco_color" class="block mb-2">+ <span>&diams; </span>{{ (brandLoyaltySubtotal.rco_color || 0).toFixed(2) }} {{ $t('Pts') }}</span>
                    </span>
                  </div>
                  <div v-if="points['rco'] && points['rco']['earnedPoints']" class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('R+CO POINTS EARNED:')}}
                    </span>
                    <span class="pro_checkout_pts absolute p-0">
                      <span
                        class="pro_pt_type_totals flex justify-between text-left font-black text-h5 leading-h3 tracking-md uppercase bg-green text-white px-2 relative"
                        :style="{ backgroundColor: `#${points['rco']['color']}` }"
                      >
                        <span class="pro_pt_amt">
                          {{ points['rco']['earnedPoints'].toFixed(2) }}
                        </span>
                        <span class="pro_pt_type">
                          {{ $t('points') }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div
                    v-if="points['rco'] && points['rco']['earnedPoints']"
                    class="text-sm flex justify-end align-right mx-10 my-2">
                    *Final earning amount is determined at time of shipment.</div>
                </div>
              </div>
              <div id="sc"
                   class="pro_checkout_brand md:pr-15 smith-cult"
                   v-if="groupedProducts.sc && groupedProducts.sc.length">
                <div class="pro_checkout_brandbar pb-5">
                  <i class="icon-smith-cult text-caption"></i>
                </div>
                <product :products-in-cart="groupedProducts.sc"/>
                <div class="checkout_brand_subtotal m-0 text-right relative">
                  <div class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('SMITH & CULT SUBTOTAL:')}}
                    </span>
                    <span class="price text-md leading-none tracking-thick md:absolute text-left md:text-h3">
                      <span class="block mb-2">{{ brandSubtotal.sc | price }}</span>
                      <span v-if="brandLoyaltySubtotal.sc" class="block mb-2">+ <span>&diams; </span>{{ (brandLoyaltySubtotal.sc || 0).toFixed(2) }} {{ $t('Pts') }}</span>
                    </span>
                  </div>
                  <div v-if="points['sc'] && points['sc']['earnedPoints']" class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('SMITH & CULT POINTS EARNED:')}}
                    </span>
                    <span class="pro_checkout_pts absolute p-0">
                      <span
                        class="pro_pt_type_totals flex justify-between text-left font-black text-h5 leading-h3 tracking-md uppercase bg-red text-white px-2 relative"
                        :style="{ backgroundColor: `#${points['sc']['color']}` }"
                      >
                        <span class="pro_pt_amt">
                          {{ points['sc']['earnedPoints'].toFixed(2) }}
                        </span>
                        <span class="pro_pt_type">
                          {{ $t('points') }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div
                    v-if="points['sc'] && points['sc']['earnedPoints']"
                    class="text-sm flex justify-end align-right mx-10 my-2">
                    *Final earning amount is determined at time of shipment.</div>
                </div>
              </div>
              <div id="v76"
                   class="pro_checkout_brand md:pr-15 v76-by-vaughn"
                   v-if="groupedProducts.v76 && groupedProducts.v76.length">
                <div class="pro_checkout_brandbar pb-5">
                  <i class="icon-v76 text-h1"></i>
                </div>
                <product :products-in-cart="groupedProducts.v76"/>
                <div class="checkout_brand_subtotal m-0 text-right relative">
                  <div class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('v76 by Vaughn SUBTOTAL:')}}
                    </span>
                    <span class="price text-md leading-none tracking-thick md:absolute text-left md:text-h3">
                      <span class="block mb-2">{{ brandSubtotal.v76 | price }}</span>
                      <span v-if="brandLoyaltySubtotal.v76" class="block mb-2">+ <span>&diams; </span>{{ (brandLoyaltySubtotal.v76 || 0).toFixed(2) }} {{ $t('Pts') }}</span>
                    </span>
                  </div>
                  <div v-if="points['v76'] && points['v76']['earnedPoints']" class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('v76 by Vaughn POINTS EARNED:')}}
                    </span>
                    <span class="pro_checkout_pts absolute p-0">
                      <span
                        class="pro_pt_type_totals flex justify-between text-left font-black text-h5 leading-h3 tracking-md uppercase bg-yellow text-white px-2 relative"
                        :style="{ backgroundColor: `#${points['v76']['color']}` }"
                      >
                        <span class="pro_pt_amt">
                          {{ points['v76']['earnedPoints'].toFixed(2) }}
                        </span>
                        <span class="pro_pt_type">
                          {{ $t('points') }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div
                    v-if="points['v76'] && points['v76']['earnedPoints']"
                    class="text-sm flex justify-end align-right mx-10 my-2">
                    *Final earning amount is determined at time of shipment.</div>
                </div>
              </div>
              <div id="unclassified"
                   class="pro_checkout_brand md:pr-15 unclassified"
                   v-if="groupedProducts.unclassified && groupedProducts.unclassified.length">
                <div class="pro_checkout_brandbar pb-5">
                  {{ $t('Unclassified Brand') }}
                </div>
                <product :products-in-cart="groupedProducts.unclassified"/>
                <div class="checkout_brand_subtotal m-0 text-right relative">
                  <div class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('Unclassified SUBTOTAL:')}}
                    </span>
                    <span class="price text-md leading-none tracking-thick md:absolute text-left md:text-h3">
                      <span class="block mb-2">{{ brandSubtotal.unclassified | price }}</span>
                      <span v-if="brandLoyaltySubtotal.unclassified" class="block mb-2">+ <span>&diams; </span>{{ (brandLoyaltySubtotal.unclassified || 0).toFixed(2) }} {{ $t('Pts') }}</span>
                    </span>
                  </div>
                  <div v-if="points['unclassified'] && points['unclassified']['earnedPoints']" class="brand-subtotal relative">
                    <span class="title text-h5 leading-h4 uppercase tracking-md">
                      {{ $t('Unclassified POINTS EARNED:')}}
                    </span>
                    <span class="pro_checkout_pts absolute p-0">
                      <span
                        class="pro_pt_type_totals flex justify-between text-left font-black text-h5 leading-h3 tracking-md uppercase bg-yellow text-white px-2 relative"
                        :style="{ backgroundColor: `#${points['unclassified']['color']}` }"
                      >
                        <span class="pro_pt_amt">
                          {{ points['unclassified']['earnedPoints'].toFixed(2) }}
                        </span>
                        <span class="pro_pt_type">
                          {{ $t('points') }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div
                    v-if="points['unclassified'] && points['unclassified']['earnedPoints']"
                    class="text-sm flex justify-end align-right mx-10 my-2">
                    *Final earning amount is determined at time of shipment.</div>
                </div>
              </div>
              <div class="cart-admin-toolbar mb-3">
                <button-with-icon
                  class="clear-cart-button"
                  :icon-class="'ss-trash'"
                  @click.native="clearCart"
                  :loading="clearCartProcessing"
                >{{ $t('Remove All Cart Items') }}</button-with-icon>
              </div>
            </div>
            <div v-else-if="!cartIsLoaded" style="height: 80vh" class="flex items-center justify-center relative text-dark font-sans uppercase text-center text-lg leading-xs tracking-mid py-xl">
              Loading...<br>
              <loader class="inline-block" :color="`#000`" aria-label="Loading" />
            </div>
            <div v-else>
              <h3 class="text-center uppercase px-4 py-12">
                {{ $t('There are no items in your cart') }}
              </h3>
              <div class="text-center uppercase px-4 py-12" v-if="productsInCart.length">
                {{ $t('Please select continue below after selecting the shipping location.') }}
              </div>
            </div>
          </div>
          <aside class="pro_checkout_side" id="affix" :class="{ affix: affix }">
            <div class="pro_checkout_scroller pro_scroller h-full">
              <div class="viewport h-full">
                <perfect-scrollbar ref="scroll">
                  <div class="px-7 pb-7 pt-11">
                    <h2 class="pro_checkout_sp hidden font-serif font-black text-h2 leading-none text-dark capitalize mb-6 md:block">
                      {{ $t('Your cart') }}
                    </h2>
                    <div>
                      <div class="pro_checkout_sp" v-if="productsInCart.length">
                        <shipping-location />
                      </div>
                      <div class="discount" v-if="productsInCart.length">
                        <div class="discount-coupon-form">
                          <div class="flex">
                            <base-input
                              :placeholder="$t('Enter Promo')"
                              :type="'text'"
                              :input-class="'text-white bg-transparent-25'"
                              v-model.trim="couponCode"
                              class="coupon-code"
                              id="couponinput"
                              @keyup.enter="setCoupon"
                              v-if="!appliedCoupon"
                            />
                            <base-input
                              :type="'text'"
                              :input-class="'text-white bg-transparent-25'"
                              :value="appliedCoupon.code"
                              @keyup.enter="setCoupon"
                              v-if="appliedCoupon.code"
                              :readonly="true"
                            />
                            <button-full
                              :type="'submit'"
                              class="coupon-button"
                              :loading="couponProcessing"
                              @click.native="(appliedCoupon) ? clearCoupon() : setCoupon()"
                            >
                              {{ $t((appliedCoupon) ? 'Clear code' : 'Use code') }}
                            </button-full>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="pro_checkout_side_nav pro_checkout_cart">
                    <template  v-for="brand in brandsSummary"
                               :class="isActive(brand.id)"
                               @click="scrollTo(brand.id)">
                      <li v-if="groupedProducts[brand.id].length"
                          :key="brand.id"
                          class="nav-list-item relative overflow-visible border border-grey-4 border-solid px-7 cursor-pointer">
                            <span class="brand-name text-dark tracking-average font-bold block leading-none text-heading-page uppercase">
                        {{ brand.name }}
                      </span>
                        <span class="brand-price italic text-heading-page leading-h5 font-serif">
                        <span class="block mb-2">{{ brandSubtotal[brand.id] | price }}</span>
                        <span v-if="brandLoyaltySubtotal[brand.id]" class="block mb-2">+ <span>&diams; </span> {{ (brandLoyaltySubtotal[brand.id]|| 0).toFixed(2) }} {{ $t('Pts') }}</span>
                      </span>
                      </li>
                    </template>
                  </ul>
  <!--                 <div class="pro_checkout_calc_cta pro_checkout_sp px-7 pt-7">
                    <div class="uppercase tracking-md text-h6 leading-thick mb-sm">
                      <span class="text-dark">
                        {{ $t('Profit estimate on') }}
                      </span>
                      <span class="cursor-pointer">
                        <tooltip class="tooltip">
                          <span slot="pointer">
                            {{ $t('resale items:') }}
                          </span>
                          <div slot="content">
                            {{ $t('RESALE ITEMS ARE PRODUCTS ARE PLACED IN RETAIL AREAS FOR CLIENTS TO PURCHASE, AND USED TO GROW YOUR PROFIT MARGIN. NOT FOR RESALE (NFR) ITEMS SUCH AS BACKBAR, STYLE STATION, AND TESTER PRODUCTS SHOULD NOT BE PLACED IN RETAIL AREAS. NFR ITEMS SHOULD NOT BE USED TO CALCULATE YOUR MARGIN OF PROFIT.') }}
                          </div>
                        </tooltip>
                      </span>
                      <span class="text-dark inline-block">
                        {{ $t(`(${profitPercent}% markup)`) }}
                      </span>
                    </div>
                    <div class="text-base font-bold leading-none">
                      {{ totalProfit | price }}
                    </div>
                    <button-outline :type="'submit'" class="calculate-button w-full" @click.native="calcPopupIsActive = !calcPopupIsActive">
                      <slot>
                        <span>{{ $t('Calculate your profits') }}</span>
                        <i class="ss-gizmo ss-calculator calculate-icon text-lg absolute"></i>
                      </slot>
                    </button-outline>
                  </div> -->
                </perfect-scrollbar>
              </div>
            </div>
            <div class="pro_checkout_sttl md:fixed z-2 bg-grey-7" :class="{'free-shipping': freeShippingThreshold > 0}" v-if="productsInCart.length">
              <div
                class="px-5 py-7 z-2 relative"
              >
                <div v-if="productsInCart.length" class="uppercase text-h6 tracking-md leading-none mb-xs">
                  {{ $t('Cart total:') }}
                </div>
                <div v-if="productsInCart.length" class="uppercase tracking-thick leading-none text-h3 mb-2">
                  {{ getCartTotal | price }}
                </div>
                <div v-if="productsInCart.length && brandsLoyaltyTotal" class="uppercase tracking-thick leading-none text-h3 mb-4">
                  + <span>&diams; </span>{{ (brandsLoyaltyTotal || 0).toFixed(2) }} {{ $t('Pts')}}
                </div>
                <button-full :type="'submit'" :loading="checkoutLoading" @click.native="openRedeemPopup()" class="checkout-button relative w-full px-5">
                  <span v-if="productsInCart.length">{{ $t('Checkout') }}</span>
                  <i class="ss-gizmo checkout-icon ss-right absolute text-h5"></i>
                </button-full>
                <free-shipping-bar class="free-shipping-bar" :subtotal="getCartTotal" v-if="productsInCart.length"></free-shipping-bar>
              </div>
            </div>
          </aside>
        </section>
      </article>
  <!--     <div
        class="profit_calc_container pinned w-full h-full bg-cover bg-no-repeat bg-center text-white"
        :class="{ active: calcPopupIsActive, affix: affix }">
        <div class="profit_calc_head relative">
          <span class="profit_calc_header font-bold text-h5 leading-none tracking-xl text-center uppercase text-brown-2 block mb-25px">
            {{ $t('Calculate Your Profits') }}
          </span>
          <p class="profit_calc_title font-black font-serif">
            {{ $t('Based on a markup of ') }}
            <span contenteditable="true"
                  :type="'number'"
                  @input="inputPercent"
                  class="profit_calc_input w-auto text-brown-2 inline border-dotted border-brown-2">
              {{ profitPercent }}
            </span>
            {{ $t(' of the resale items in your cart, you stand to make a profit of ') }}
            <span class="profit_calc_profit font-black font-serif text-brown-2">
              {{ totalProfit | price }}
            </span>
          </p>
          <div
            class="close-button absolute text-white block border-solid border-white opacity-3 leading-none cursor-pointer hover:opacity-100"
            @click="calcPopupIsActive = false">
            <i class="ss-gizmo ss-delete"></i>
          </div>
        </div>
        <ul class="profit_calc_brands">
          <li v-if="groupedProducts.rco.length"
              class="profit_calc_brand font-bold text-heading-page tracking-lg uppercase leading-none text-secondary">
            <div class="profit_calc_brand_container text-right relative">
              <span class="brand-name text-white absolute">
                <i class="icon-rco text-xl"></i>
              </span>
              <span>
                {{ $t(`${groupedProducts.rco.length} Resale Items`) }}
              </span>
              <span class="brand-profit relative inline-block float-right">
                <span class="block text-heading-page leading-none">
                  {{ subtotalProfit.rco | price }}
                </span>
                <span class="profit-title absolue font-sans font-bold text-h6 leading-none">{{ $t('Profit') }}</span>
              </span>
            </div>
          </li>
          <li v-if="groupedProducts.sc.length"
              class="profit_calc_brand font-bold text-heading-page tracking-lg uppercase leading-none text-secondary">
            <div class="profit_calc_brand_container text-right relative">
              <span class="brand-name text-white absolute">
                <i class="icon-smith-cult text-h3"></i>
              </span>
              <span>
                {{ $t(`${groupedProducts.sc.length} Resale Items`) }}
              </span>
              <span class="brand-profit relative inline-block float-right">
                <span class="block text-heading-page leading-none">
                  {{ subtotalProfit.sc | price }}
                </span>
                <span class="profit-title absolue font-sans font-bold text-h6 leading-none">{{ $t('Profit') }}</span>
              </span>
            </div>
          </li>
          <li v-if="groupedProducts.v76.length"
              class="profit_calc_brand font-bold text-heading-page tracking-lg uppercase leading-none text-secondary">
            <div class="profit_calc_brand_container text-right relative">
              <span class="brand-name text-white absolute">
                <i class="icon-v76 text-heading"></i>
              </span>
              <span>
                {{ $t(`${groupedProducts.v76.length} Resale Items`) }}
              </span>
              <span class="brand-profit relative inline-block float-right">
                <span class="block text-heading-page leading-none">
                  {{ subtotalProfit.v76 | price }}
                </span>
                <span class="profit-title absolue font-sans font-bold text-h6 leading-none">{{ $t('Profit') }}</span>
              </span>
            </div>
          </li>
        </ul>
      </div> -->
      <redeem-points-popup />
      <shipping-location-popup />
    </div>
  </no-ssr>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ShippingLocation from 'theme/components/theme/blocks/Cart/ShippingLocation'
import Product from '../components/theme/blocks/Cart/Product'
import Microcart from '@vue-storefront/core/compatibility/components/blocks/Microcart/Microcart'
import Cart from '../mixins/pages/Cart'
import FreeShippingBar from 'theme/components/theme/blocks/Cart/FreeShippingBar'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import RedeemPointsPopup from 'theme/components/core/blocks/Checkout/RedeemPointsPopup'
import ShippingLocationPopup from 'theme/components/theme/blocks/Cart/ShippingLocationPopup'
import ProfitsCalculate from '../mixins/blocks/ProfitsCalculate'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'
import i18n from '@vue-storefront/i18n'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import { clearTimeout, setTimeout } from 'timers'
import NoSSR from 'vue-no-ssr'
import Loader from 'theme/components/theme/Loader'
import { mapState } from "vuex";

export default {
  name: 'Cart',
  components: {
    ButtonWithIcon,
    ButtonFull,
    BaseInput,
    ShippingLocation,
    Product,
    PerfectScrollbar,
    RedeemPointsPopup,
    ShippingLocationPopup,
    'no-ssr': NoSSR,
    Loader,
    FreeShippingBar
  },
  mixins: [
    Microcart,
    Cart,
    ProfitsCalculate,
    PriceRules,
    EarnedPoints
  ],
  metaInfo () {
    return {
      title: this.$route.meta.title || i18n.t('Cart'),
      meta: this.$route.meta.description ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }] : []
    }
  },
  data () {
    return {
      rewardTimeout: null,
      affix: false,
      activeBrand: '',
      checkoutLoading: false,
      profitPercent: 100,
      shippingLocations: [
        {
          value: 'default',
          label: 'Default Location'
        },
        {
          value: 'main',
          label: 'Main Location'
        }
      ],
      brandsSummary: [
        {
          name: 'R+Co',
          id: 'rco'
        },
        {
          name: 'R+Co Bleu',
          id: 'rco_bleu'
        },
        {
          name: 'R+Color',
          id: 'rco_color'
        },
        {
          name: 'Smith & Cult',
          id: 'sc'
        },
        {
          name: 'V76 by Vaughn',
          id: 'v76'
        }
      ],
      calcPopupIsActive: false,
      couponCode: '',
      couponProcessing: false,
      clearCartProcessing: false,
      notification: false,
      tabletPoint: 768,
      points: {}
    }
  },
  async beforeMount () {
    this.points = await this.getRewards()
  },
  beforeDestroy () {
    this.$bus.$off('cart-after-itemchanged', this.updateRewards)
  },
  mounted () {
    if (!this.$isServer) {
      window.addEventListener('scroll', this.scrolling)

      this.$bus.$on('cart-after-itemchanged', this.updateRewards)
    }

    // this.removeRewardsProducts()

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'cart/cart/UPD_TOTALS' && this.couponProcessing) {
        this.couponProcessing = false
        this.addCouponPressed = false
        if (this.notification) {
          this.$store.dispatch('notification/spawnNotification', this.notification)
          this.notification = false
        }
      }
    })
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.cart.processing,
      freeShippingThreshold: state => state.cart.freeShippingThreshold
    }),
    isAdmin () {
      return !!this.$route.query.admin
    },
    brandSubtotal () {
      let brandProducts = this.groupedProducts
      let brandSubtotal = {}

      for (let key in brandProducts) {
        let products = brandProducts[key]

        brandSubtotal[key] = 0

        products.forEach(product => {
          let rulePrice = this.getRulePrice(product)
          let loyaltyQty = ((product.product_option || {}).extension_attributes || {}).qty_using_points || 0

          if (rulePrice) {
            brandSubtotal[key] += rulePrice * (product.qty - loyaltyQty)
          } else {
            brandSubtotal[key] += product.price * (product.qty - loyaltyQty)
          }
        })
      }

      return brandSubtotal
    },
    brandLoyaltySubtotal () {
      let brandProducts = this.groupedProducts
      let brandSubtotal = {}

      for (let key in brandProducts) {
        let products = brandProducts[key]

        brandSubtotal[key] = 0

        products.forEach(product => {
          let rulePrice = this.getRulePrice(product)

          let loyaltyQty = ((product.product_option || {}).extension_attributes || {}).qty_using_points || 0

          if (rulePrice) {
            brandSubtotal[key] += rulePrice * (loyaltyQty)
          } else {
            brandSubtotal[key] += product.price * (loyaltyQty)
          }
        })
      }

      return brandSubtotal
    },
    brandsTotal () {
      let subtotals = this.brandSubtotal
      let total = 0

      for (let key in subtotals) {
        total += subtotals[key]
      }

      return total
    },
    brandsLoyaltyTotal () {
      let subtotals = this.brandLoyaltySubtotal
      let total = 0

      for (let key in subtotals) {
        total += subtotals[key]
      }

      return total
    },
    getCartTotal () {
      let total = 0

      Object.values(this.brandSubtotal).forEach(subtotal => {
        total += subtotal
      })

      return total
    },
    totals () {
      return this.$store.getters['cart/getTotals']
    },
    grandTotal () {
      for (let i = 0; i < this.totals.length; i++) {
        const segment = this.totals[i]

        if (segment.code === 'grand_total') {
          return segment
        }
      }
      return null
    },
    subtotalProfit () {
      let brandSubtotalProfit = {}

      for (let key in this.brandSubtotal) {
        brandSubtotalProfit[key] = 0.01 * this.brandSubtotal[key] * this.profitPercent * this.getFactor
      }

      return brandSubtotalProfit
    },
    totalProfit () {
      return 0.01 * this.brandsTotal * this.profitPercent * this.getFactor
    }
  },
  methods: {
    async updateRewards () {
      if (!this.$isServer) {
        clearTimeout(this.rewardTimeout)
        this.rewardTimeout = setTimeout(async () => {
          this.points = await this.getRewards()
        }, 1000)
      }
    },
    isActive (brandId) {
      return this.activeBrand === brandId ? `${brandId} active` : brandId
    },
    offset (el) {
      let rect = el.getBoundingClientRect()
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return {
        top: rect.top + scrollTop,
        bottom: rect.top + scrollTop + el.offsetHeight
      }
    },
    scrollSpy () {
      let brandsTotals = document.querySelectorAll('.pro_checkout_brand')
      let brandsTotalsContainer = document.querySelector('.pro_checkout_container')
      let containerOffsetTop = this.offset(brandsTotalsContainer)

      brandsTotals.forEach(element => {
        let offset = this.offset(element)

        if (window.scrollY > offset.top && window.scrollY <= offset.bottom) {
          this.activeBrand = element.id
        } else if (
          window.scrollY < containerOffsetTop.top ||
          window.scrollY > containerOffsetTop.bottom
        ) {
          this.activeBrand = ''
        }
      })
    },
    isFixed () {
      let $affixItem = document.getElementById('affix')

      if (!$affixItem) return

      if ($affixItem.offsetTop - window.scrollY <= -100) {
        this.affix = true
        document.getElementById('affix').style.top = ''
      } else {
        this.affix = false
        document.getElementById('affix').style.top = ''
      }
    },
    scrolling () {
      if (document.documentElement.clientWidth < this.tabletPoint) return

      this.isFixed()
      this.scrollSpy()
    },
    scrollTo (targetId) {
      let target = this.$el.querySelector(`#${targetId}`)

      target.scrollIntoView({
        block: 'center'
      })
    },
    async openRedeemPopup () {
      this.checkoutLoading = true

      let errors = 0

      await Promise.all(this.productsInCart.map(async (product) => {
        const stockResult = await this.$store.dispatch('stock/check', { product: product, qty: product.qty || 1 }, {root: true})

        // used to get the online check result
        product.onlineStockCheckid = stockResult.onlineCheckTaskId

        // check against min qty
        const calculatedQty = ((stockResult || {}).qty || 0) - (product.qty || 1)
        const isInStock = (calculatedQty > 0 && calculatedQty > (stockResult.min_qty || (product.stock || {}).min_qty))

        if (stockResult.status === 'out_of_stock' || !isInStock) {
          errors++

          await this.$store.dispatch('cart/updateItem', { product: { errors: { stock: i18n.t('Out of the stock!') }, sku: product.sku, is_in_stock: false } }, { root: true })
          // remove item from cart
          // await this.$store.dispatch('cart/removeItem', { product: product }, {root: true})
          await this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: `${product.name} ` + i18n.t('is out of stock, please remove the item from your cart to continue.'),
            action1: { label: i18n.t('OK') }
          })
        } else if (stockResult.status === 'ok') {
          await this.$store.dispatch('cart/updateItem', { product: { info: { stock: i18n.t('In stock!') }, sku: product.product_sku, is_in_stock: true } }, { root: true })
        }
      })).then(() => {
        if (errors > 0) {
          throw new Error('Whoops! Looks like something\'s out of stock.')
        }

        this.checkoutLoading = false

        if (this.productsInCart.length) {
          this.$router.push({ name: 'checkout' })
          // this.$bus.$emit('modal-show', 'modal-redeem-points')
        } else {
          // this.$router.push({ name: 'rewards-cart' })
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: 'You have no items in your cart.',
            action1: { label: i18n.t('OK') }
          })
        }
      }).catch((err) => {
        console.log(err)
        this.checkoutLoading = false
      })
    },
    inputPercent (e) {
      let inputText = Number.parseFloat(e.target.innerText)

      if (!isNaN(inputText)) this.profitPercent = inputText
    },
    removeRewardsProducts () {
      const products = this.$store.state.cart.cartItems

      for (let i = 0; i < products.length; i++) {
        const product = products[i]

        if (product.hasOwnProperty('using_points') && product.using_points) {
          this.$store.dispatch('cart/removeItem', { product })
        }
      }
    },
    addDiscountCoupon () {
      this.addCouponPressed = true
    },
    clearCoupon () {
      this.couponProcessing = true
      this.removeCoupon().then(() => {
        this.notification = {
          type: 'success',
          message: i18n.t('Your coupon was successfully cleared.'),
          action1: { label: i18n.t('OK') }
        }
        this.couponCode = ''
      })
      this.addCouponPressed = false
    },
    setCoupon () {
      this.couponProcessing = true
      this.applyCoupon(this.couponCode).then((response) => {
        if (response.code === 200 || response === true) {
          this.addCouponPressed = false
          this.notification = {
            type: 'success',
            message: i18n.t('Your coupon was successfully applied.'),
            action1: { label: i18n.t('OK') }
          }
        } else {
          this.couponCode = ''
          this.couponProcessing = false
        }
      }).catch(() => {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t("You've entered an incorrect coupon code. Please try again."),
          action1: { label: i18n.t('OK') }
        })
        this.couponProcessing = false
      })
    },
    clearCart () {
      this.clearCartProcessing = true
      this.$store.dispatch('cart/clearCart').then(() => {
        this.clearCartProcessing = false
        this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: i18n.t('All items were removed from your cart.'),
          action1: { label: i18n.t('OK') }
        })

        if (!this.$isServer) {
          window.scrollTo(0, 0)
        }
      }).catch((err) => {
        console.log(err)
        this.clearCartProcessing = false
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t('Unable to remove all items from your cart.'),
          action1: { label: i18n.t('OK') }
        })
      })
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrolling)
  }
}
</script>

<style scoped lang="scss">
  $yellow: #f3f281;
  $green: #00e1ba;
  $red: #f84876;
  $brown: #bdac84;
  $brown-2: #8f7b4c;
  $grey-7: #e8e8e8;
  $grey-9: #e5e5e5;

  .cart-section {
    &:after {
      @apply absolute hidden bg-grey-7 z-2;
      background-image: linear-gradient(to right, $grey-7 0%, $grey-9 100%);
      content: '';
      height: 190px;
      width: calc(100% - 300px);
      bottom: 0;
      right: 0;

      @screen md {
        @apply block;
      }
    }
  }

  .ps {
    height: 100%;
  }

  .viewport {
    @apply w-full;

    @screen md {
      width: 300px;
    }
  }

  .pro_checkout_brandbar {
    @apply pt-5;

    @screen md {
      padding-top: 106px;
    }
  }

  .table {
    .checkout_item_img {
      width: 100px;
    }

    .checkout_item_qty {
      width: 80px;
    }

    .checkout_item_subtotal {
      width: 150px;
    }

    .checkout_item_remove {
      @apply text-right;
      width: 60px;
    }
  }

  .checkout_brand_subtotal {
    border-top: solid 1px #000;
    padding: 0 0 68px 0;
  }

  .brand-subtotal {
    @apply pt-4;

    @screen md {
      padding: 35px 250px 0 0;
    }

    .price {
      top: 30px;
      width: 210px;
      right: 0;
    }

    .pro_checkout_pts {
      @apply relative;
      float: right;

      @screen md {
        @apply absolute;
        float: none;
        right: 0;
        top: 30px;
        width: 210px;
      }
    }

    .pro_pt_type_totals {
      width: 176px;

      &.smith-cult {
        height: 33px;
        padding: 0 12px 0 14px;
      }
    }
  }

  .qty-input {
    &::v-deep {
      input {
        @apply border-grey-16 py-0 px-sm text-center leading-h6;
        width: 50px;
      }
    }
  }

  .remove-icon {
    transition: color .5s ease;
  }

  .pro_checkout_side {
    @apply relative w-full h-auto pb-0;
    background-image: url('/assets/pattern-tile.png');
    background-repeat: repeat;
    background-position: left top;
    z-index: 1;

    @screen md {
      @apply absolute h-full;
      left: 0;
      padding-bottom: 160px;
      top: 0;
      width: 300px;
    }

    &.affix {
      @screen md {
        @apply fixed;
      }
    }
  }

  .shipping-location {
    &::v-deep {
      select {
        @apply border-white;
        height: 38px;
        padding-bottom: 0;
        padding-top: 0;
      }

      .icon {
        height: 8px;
        width: 8px;
      }
    }
  }

  .coupon-code {
    &::v-deep {
      input {
        @apply border-white;
        height: 38px;
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  .clear-cart-button {
    padding: 6px 3rem 6px 18px;
    @apply bg-transparent border-3 text-black;

    i {
      padding-top: 2px;
    }

    @screen md {
      min-width: 100px;
    }
  }

  .coupon-button {
    padding: 6px 18px;

    @screen md {
      min-width: 100px;
    }
  }

  .tooltip {
    &::v-deep {
      .text {
        bottom: 35px;
        width: 260px;

        &:before {
          left: 65%;
        }
      }
    }
  }

  .nav-list-item {
    border-width: 0 0 1px;
    padding-top: 18px;
    padding-bottom: 6px;
    transition: background-color .5s ease;

    &:first-child {
      @apply border-grey-4;
      border-top-width: 1px;
    }

    &.active {
      .brand-name,
      .brand-price {
        @apply text-white;
      }
    }

    .brand-name {
      margin-bottom: 3px;
    }

    &.rco {
      &:hover {
        background-color: rgba(0,225,186,0.5);
      }

      &.active {
        @apply bg-green;
      }
    }

    &.rco_bleu,&.rco_color {
      &:hover {
        span {
          color: #fff !important;
        }

        background-color: rgba(#0e3f6f, .5);
      }

      &.active {
        span {
          color: #fff !important;
        }

        background-color: #0e3f6f;
      }
    }

    &.sc {
      &:hover {
        background-color: rgba(248,72,118,0.5);
      }

      &.active {
        @apply bg-red;
      }
    }

    &.v76 {
      &:hover {
        background-color: rgba(243,242,129,0.5);
      }

      &.active {
        @apply bg-yellow;
      }
    }
  }

  .calculate-button {
    @apply flex bg-brown border-0 mt-5 px-5 text-left;
    background-image: linear-gradient(to right, $brown 0%, $brown-2 100%);
    margin-bottom: 25px;
    padding-bottom: 24px;
    padding-top: 24px;

    &:after {
      @apply border-4 border-solid border-transparent absolute w-full h-full z-1;
      content: '';
      top: 0;
      left: 0;
      transition: border-color .5s ease;
    }

    &:hover {
      &:after {
        @apply border-primary;
      }
    }
  }

  .calculate-icon {
    right: 15px;
    top: 20px;
  }

  .pro_checkout_sttl {
    @apply w-full h-full;

    .free-shipping-bar {
      height: 140px;
    }

    @screen md {
      bottom: 0;
      left: 0;
      height: 190px;
      width: 300px;

      &.free-shipping {
        height: calc(190px + 40px);
      }
    }

    &:before {
      @apply absolute hidden rounded-100;
      content: '';
      width: 90%;
      height: 10px;
      top: 0;
      left: 5%;
      z-index: 0;
      box-shadow: 0 0 9px rgba(0, 0, 0, .65);
      border-radius: 100%;
      background-clip: padding-box;

      @screen md {
        @apply block;
      }
    }

    &:after {
      @apply hidden w-full h-full absolute z-1 bg-grey-7;
      content: '';
      top: 0;
      left: 0;

      @screen md {
        @apply block;
      }
    }
  }

  .checkout-button {
    @apply text-left;
    padding-bottom: 18px;
    padding-top: 18px;
  }

  .checkout-icon {
    line-height: 62px;
    right: 20px;
    top: 1px;
  }

  .pro_checkout_container {
    @apply w-full relative p-7;

    @screen md {
      min-height: 100vh;
      padding: 0 0 200px 360px;
      transform: translate3d(0, 0, 0);
    }
  }

  .profit_calc_container {
    top: -9999px;
    left: -9999px;
    z-index: 9997;
    padding: 45px 0 0 100px;
    position: absolute;
    margin: 100px 0 0 300px;
    background-image: url('/assets/profit-calculator-bg.jpg');
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity 1s ease, top 0s ease 1s, left 0s ease 1s;

    &.active {
      top: 0;
      left: 0;
      opacity: 1;
      transition: opacity 1s ease;

      &.affix {
        margin-top: 0;
        position: fixed;
      }
    }

    .profit_calc_head {
      width: 780px;
      transition: all 1s ease;
    }

    .profit_calc_title {
      font-size: 35px;
      line-height: 45px;
    }

    .profit_calc_profit {
      font-size: 45px;
      line-height: 53px;
    }

    .profit_calc_input {
      border-width: 0 0 1px;

      &:after {
        content: '\0025';
      }
    }

    .close-button {
      border-width: 0 0 2px;
      font-size: 36px;
      top: -14px;
      right: 0;
      transition: opacity .25s ease;
    }

    .profit_calc_brands {
      margin-left: -100px;
      margin-top: 35px;

      .brand-name {
        left: 0;
      }
    }

    .profit_calc_brand {
      padding: 35px 0 35px 100px;
      margin-bottom: 1px;
      background-color: rgba(235, 230, 217, .05);

      .brand-profit {
        padding-left: 95px;

        &:before {
          @apply border-solid border-secondary absolute;
          content: '';
          border-width: 1px 0 0;
          width: 67px;
          height: 0;
          bottom: 21px;
          left: 14px;
          transform-origin: center center;
          transform: rotate(-45deg);
        }
      }

      .profit-title {
        bottom: -15px;
        right: 0;
      }

      .profit_calc_brand_container {
        width: 780px;
      }
    }
  }
</style>
