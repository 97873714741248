<template>
  <div v-if="!isTotal">
    <div class="prices">
      <span class="text-sm price-special" v-if="product.special_price">
        {{ product.price | price }}&nbsp;
      </span>
      <span class="text-xs line-through" v-if="product.special_price">
          {{ product.originalPrice | price }}
      </span>
      <span class="text-sm" data-testid="productPrice" v-if="!product.special_price">
          {{ product.price | price }}
      </span>
      <span class="text-sm font-semibold" v-else>
        {{ product.regular_price | price }}
      </span>
    </div>
  </div>
  <div class="font-semibold" v-else>
    <div class="prices">
      <span class="text-sm price-special" v-if="product.special_price">
        {{ product.price * productQty | price }}&nbsp;
      </span>
      <span class="text-xs line-through" v-if="product.special_price">
        {{ product.originalPrice * productQty | price }}
      </span>
      <span class="text-sm font-semibold" v-if="!product.special_price" data-testid="productPrice">
        {{ product.price * productQty | price }}
      </span>
      <span class="text-sm font-semibold" v-else>
        {{ product.regular_price * productQty | price }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    isTotal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    productQty () {
      return this.product.qty - (((this.product.product_option || {}).extension_attributes || {}).qty_using_points || 0)
    }
  }
}
</script>
