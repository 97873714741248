<template>
  <section id="rewards-cart" class="relative">
    <div class="pro_checkout_container relative w-full">
      <div class="pro_checkout_brand active" :class="{ affix: affix }">
        <div class="pro_loyalty_brandbar bg-white w-full relative">
          <div class="pro_loyalty_head z-1 relative bg-white">
            <div class="pro_cn_filter loyalty_spending py-md px-xl height-auto ml-lg float-left">
              <span class="pro_loyalty_title font-bold text-h5 leading-none tracking-lg uppercase block mb-md">
                <span>{{ $t('Redeem') }}</span>
                <span>{{ $t('R+CO') }}</span>
                <span>{{ $t('Products') }}</span>
              </span>
            </div>
            <div class="pro_cn_point_filter absolute float-left">
              <span class="uppercase font-bold text-xs text-grey-22 tracking-md leading-none">
                {{ $t('Reward point type:') }}
              </span>
              <div class="filters flex">
                <base-checkbox
                  class="checkbox mr-sm"
                  id="backbar"
                  v-model="backbar"
                  @click="backbar = !backbar"
                >
                  {{ $t('Backbar') }}
                </base-checkbox>
                <base-checkbox
                  class="checkbox mr-sm"
                  id="station"
                  v-model="station"
                  @click="station = !station"
                >
                  {{ $t('Station') }}
                </base-checkbox>
                <base-checkbox
                  class="checkbox"
                  id="tester"
                  v-model="tester"
                  @click="tester = !tester"
                >
                  {{ $t('Tester') }}
                </base-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="pro_grid_wrap clearfix">
          <div class="pro_grid_row clearfix opacity-100 pl-15 bg-white py-xl">
            <div v-for="(product, index) in products" :key="index">
              <div class="pro_grid_products_title inline-block float-left pr-xl font-black text-xl font-serif leading-h2 relative">
                {{ product.title }}
              </div>
              <ul class="pro_grid_list clearfix">
                <li
                  class="pro_grid_product block float-left text-center"
                  @click="openQuickView()"
                  v-for="(product, index) in product.products">
                  <div class="pro_grid_loyalty relative pt-5 pb-10 bg-white text-dark cursor-pointer">
                    <img :src="product.image" class="block m-auto relative z-0" :alt="product.name" width="175" height="320">
                    <span class="pro_grid_product_name relative block mx-auto mb-xs text-base leading-h6">
                      {{ product.name }}
                    </span>
                    <span class="pro_grid_price block relative italic text-above-md leading-none font-serif">
                      {{ product.price }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <aside class="pro_checkout_side h-full" id="affix" :class="{ affix: affix }">
      <div class="pro_checkout_scroller pro_scroller h-full">
        <div class="viewport h-full">
          <perfect-scrollbar>
            <div class="px-7 pb-7 pt-11">
              <h2 class="pro_checkout_sp font-serif font-black text-h2 leading-none text-dark capitalize">{{ $t('Redeem Your Points') }}</h2>
            </div>
            <ul class="pro_checkout_side_nav pro_checkout_cart">
              <li
                v-for="(brand, index) in brandsSummary"
                :key="index"
                :class="isActive(brand.id)"
                @click="activateBrand(brand.id)"
                class="nav-list-item relative overflow-visible border border-black border-solid px-7 cursor-pointer"
              >
                <span class="brand-name text-dark tracking-average font-bold block leading-none text-heading-page uppercase">
                  {{ brand.name }}
                </span>
                <span class="brand-price block font-black text-h6 tracking-md uppercase text-brown-2">
                  {{ brand.points }}
                </span>
              </li>
            </ul>
          </perfect-scrollbar>
        </div>
        <div class="pro_cart_view absolute bg-white" :class="{ active: isItemsActive }">
          <div v-for="(item, index) in selectedItems" :key="index">
            <span class="pro_cart_brand block bg-black text-white font-bold text-h5 leading-none tracking-average uppercase">
              {{ item.brandName }}
            </span>
            <ul class="pro_cart_list relative">
              <li class="pro_cart_item bg-grey-11 relative clearfix" v-for="(product, index) in item.products">
                <div class="col pro_cart_img float-left">
                  <img :src="product.image" class="block" :alt="product.name">
                </div>
                <div class="col pro_cart_desc px-5 float-left">
                  <span class="pro_cart_desc_name text-dark text-h4 block leading-base">
                    {{ product.name }}
                  </span>
                  <span class="pro_cart_desc_sub text-grey-2 text-h4 block leading-base">
                    {{ product.description }}
                  </span>
                  <span class="pro_cart_desc_points text-grey-2 text-h4 block leading-base pt-sm uppercase">
                    {{ product.points }}
                  </span>
                  <div class="btn-remove absolute inline-block p-0 text-grey-2 hover:text-black bg-transparent font-bold text-h5 leading-loose tracking-lg uppercase z-2 cursor-pointer">
                    <i class="ss-icon ss-delete text-heading-page leading-none"></i>
                  </div>
                </div>
                <div class="col pro_cart_qty pt-xl relative float-left inline-block">
                  <base-input class="pro_cart_input_qty" :type="'text'" :value="1" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pro_checkout_sttl fixed z-2 bg-grey-7">
        <div class="z-2 relative">
          <div class="selected-items text-average">
            {{ $t('1 item selected') }}
          </div>
          <div class="flex">
            <button-full class="show-items-button flex-grow relative w-full px-5" @click.native="isItemsActive = !isItemsActive">
              <span>{{ $t('Show items') }}</span>
              <i class="ss-gizmo checkout-icon ss-up absolute text-h5" :class="[isItemsActive ? 'ss-down' : 'ss-up']"></i>
            </button-full>
            <button-full
              class="checkout-button flex-grow relative w-full px-5"
              @click.native="goToCheckout()"
            >
              <span>{{ $t('Complete') }}</span>
              <i class="ss-gizmo checkout-icon ss-right absolute text-h5"></i>
            </button-full>
          </div>
        </div>
      </div>
    </aside>
    <redeem-points-quick-view />
  </section>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import RedeemPointsQuickView from 'theme/components/core/blocks/Checkout/RedeemPointsQuickView'

export default {
  components: {
    ButtonFull,
    BaseInput,
    BaseCheckbox,
    PerfectScrollbar,
    RedeemPointsQuickView
  },
  data () {
    return {
      backbar: false,
      station: true,
      tester: true,
      affix: false,
      activeBrand: 'vaughn',
      isItemsActive: false,
      products: [],
      rcoProducts: [
        {
          title: 'Samples',
          products: [
            {
              name: 'SAND CASTLE Dry Texture Crème Deluxe Sample',
              price: '1.50 Loyalty Points',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/s/a/sand-castle-tandem-deluxe-thumb.png'
            }, {
              name: 'WATERFALL Moisture + Shine Lotion Deluxe Sample',
              price: '1.50 Loyalty Points',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/r/_/r_co_waterfall_ds_thumb.png'
            }
          ]
        }, {
          title: 'Not for Resale',
          products: [
            {
              name: 'ATLANTIS Moisturizing Shampoo Liter',
              price: '39.00 Loyalty Points',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/l/b/lbp-pro_atlantis-b5_sh_liter_thumb_1.png'
            }, {
              name: 'ATLANTIS Moisturizing Conditioner Liter',
              price: '39.00 Loyalty Points',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/l/b/lbp-pro_atlantis-b5_cond_liter_thumb_1.png'
            }, {
              name: 'CACTUS Texturizing Shampoo Liter',
              price: '39.00 Loyalty Points',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/c/a/cactus-liter-thumb-33.8-oz.png'
            }, {
              name: 'DALLAS Biotin Thickening Shampoo Liter',
              price: '39.00 Loyalty Points',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/l/b/lbp-pro_dallas-sh_liter_thumb_1.png'
            }, {
              name: 'DALLAS Biotin Thickening Conditioner Liter',
              price: '39.00 Loyalty Points',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/l/b/lbp-pro_dallas-cond_liter_thumb_1.png'
            }
          ]
        }
      ],
      smithCultProducts: [
        {
          title: 'Not for Resale',
          products: [
            {
              name: 'TESTER Above it All',
              price: '9.00 Tester',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/t/o/top-coat_2_1.png'
            }, {
              name: 'TESTER Basis of Everything',
              price: '9.00 Tester',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/b/a/baseofeverything-lbp_pro-s_c-naillacquers-thumb_1.png'
            }, {
              name: 'TESTER Kundalini Hustle',
              price: '9.00 Tester',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/k/u/kundalini_2.png'
            }, {
              name: 'TESTER Lovers Creep',
              price: '9.00 Tester',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/l/o/lovers_1_2.png'
            }, {
              name: 'TESTER Lovers Creep',
              price: '9.00 Tester',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/l/o/lovers_1_2.png'
            }
          ]
        }
      ],
      vaughnProducts: [
        {
          title: 'Misc',
          products: [
            {
              name: 'V76 Shampoo Liter Pump',
              price: '0.00 NFR',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/b/l/blk-v76-pump-thumb.png'
            }, {
              name: 'V76 Conditioner Liter Pump',
              price: '0.00 NFR',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/w/h/white-v76-pump-thumb.png'
            }
          ]
        }
      ],
      selectedItems: [
        {
          brandName: 'V76 BY VAUGHN',
          products: [
            {
              name: 'V76 Shampoo Liter Pump',
              description: 'Shampoo',
              points: '0.00 PTS',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_base/50x/040ec09b1e35df139433887a97daa66f/b/l/blk-v76-pump-pdp.png'
            }
          ]
        },
        {
          brandName: 'Smith & Cult',
          products: [
            {
              name: 'TESTER Above it All',
              description: '.5 OZ',
              points: '9.00 PTS',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_base/50x/040ec09b1e35df139433887a97daa66f/t/o/top-coat_1_1.png'
            }, {
              name: 'TESTER Lovaers Creep',
              points: '9.00 PTS',
              image: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_base/50x/040ec09b1e35df139433887a97daa66f/l/o/lovers_2.png'
            }
          ]
        }
      ],
      brandsSummary: [
        {
          name: 'R+CO',
          points: '24,208.92 loyalty points',
          id: 'rco'
        },
        {
          name: 'Smith & cult',
          points: '347.05 tester',
          id: 'smithCult'
        },
        {
          name: 'V76 by Vaughn',
          points: '502.40 NFR',
          id: 'vaughn'
        }
      ]
    }
  },
  mounted () {
    this.products = this.rcoProducts
    window.addEventListener('scroll', this.scrolling)
  },
  methods: {
    isActive (brandId) {
      return brandId === this.activeBrand ? `${brandId} active` : brandId
    },
    activateBrand (brandId) {
      let productsBrand = `${brandId}Products`

      this.activeBrand = brandId
      this.products = this[productsBrand]
    },
    scrolling () {
      this.isFixed()
    },
    isFixed () {
      let $affixItem = document.getElementById('affix')

      if ($affixItem.offsetTop - window.scrollY <= -100) {
        this.affix = true
        document.getElementById('affix').style.top = ''
      } else {
        this.affix = false
        document.getElementById('affix').style.top = ''
      }
    },
    openQuickView () {
      this.$bus.$emit('modal-show', 'modal-points-quick-view')
    },
    goToCheckout () {
      this.$router.push({ name: 'static-checkout' })
    }
  }
}
</script>

<style src='vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'/>
<style lang="scss" scoped>
$yellow: #f3f281;
$green: #00e1ba;
$red: #f84876;
$grey-4: #a6a6a6;
$grey: #d0d0d0;
$grey-2: #d7d7d7;
$grey-3: #eee;
$sidebar-width: 300px;

.pro_checkout_container {
  width: 1180px;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
}

.pro_checkout_brand {
  padding-right: 0;
  border-right: none;
  position: absolute;
  left: $sidebar-width;
  top: -9999px;
  width: calc(100% - $sidebar-width);
  transition: top 0s ease .5s;

  &.affix {
    padding-top: 116px;

    .pro_loyalty_brandbar {
      @apply fixed z-3;
      top: 0;
      left: $sidebar-width;
    }
  }

  &.active {
    top: 0;
    z-index: 4;
    transition: top 0s ease 0s;

    .pro_loyalty_brandbar {
      top: 0;
    }

    .pro_grid_wrap {
      opacity: 1;
    }
  }

  &.rco {
    @apply border-green;
  }

  &.smithCult {
    @apply border-red;
  }

  &.vaughn {
    @apply border-yellow;
  }

  .pro_loyalty_brandbar {
    height: 116px;
    top: -130px;
    transition: top .5s ease;

    &:after {
      @apply absolute z-0;
      content: '';
      width: 90%;
      height: 10px;
      bottom: 0;
      left: 5%;
      box-shadow: 0 0 9px rgba(0, 0, 0, .65);
      border-radius: 100%;
      background-clip: padding-box;
    }
  }

  .pro_grid_wrap {
    opacity: 1;
    transition: opacity .5s ease;
  }

  .pro_grid_row {
    left: 0;
  }

  .pro_grid_products_title {
    height: 413px;
    overflow: hidden;
    width: 216px;

    &:after {
      @apply border-solid border-black absolute;
      border-width: 1px 0 0;
      content: '';
      width: 320px;
      height: 0;
      top: 167px;
      right: -46px;
      transform-origin: center center;
      transform: rotate(-45deg);
    }
  }

  .pro_grid_product {
    width: 216px;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
    background-color: $grey-2;
    background-image: linear-gradient(to bottom, $grey-2 0%, $grey-3 100%);
  }

  .pro_grid_loyalty {
    height: 413px;
    overflow: hidden;
    transition: background-color 0.25s ease;

    &:hover {
      @apply bg-transparent text-dark;
    }
  }

  .pro_grid_product_name {
    margin-top: -70px;
    width: 140px;
  }

  .pro_loyalty_head {
    height: 116px;
  }

  .loyalty_spending {
    width: 600px;
  }

  .pro_cn_point_filter {
    left: 60px;
    top: 40px;
  }

  .checkbox {
    &::v-deep {
      label {
        @apply text-xs font-bold leading-none tracking-md uppercase;
      }
    }
  }
}

.ps {
  height: 100%;
}

.viewport {
  width: $sidebar-width;
}

.pro_checkout_sp {
  margin-bottom: 35px;
}

.pro_checkout_side {
  background-image: url('/assets/pattern-tile.png');
  background-repeat: repeat;
  background-position: left top;
  left: 0;
  padding-bottom: 110px;
  top: 0;
  width: $sidebar-width;
  height: 100%;
  position: absolute;
  z-index: 1;

  &.affix {
    position: fixed;
  }
}

.pro_checkout_sttl {
  width: $sidebar-width;
  height: 110px;
  bottom: 0;
  left: 0;

  &:before {
    @apply absolute rounded-100;
    content: '';
    width: 90%;
    height: 10px;
    top: 0;
    left: 5%;
    z-index: 0;
    box-shadow: 0 0 9px rgba(0, 0, 0, .65);
    border-radius: 100%;
    background-clip: padding-box;
  }

  &:after {
    @apply w-full h-full absolute z-1 bg-grey-7;
    content: '';
    top: 0;
    left: 0;
  }
}

.nav-list-item {
  border-width: 0 0 1px;
  padding-top: 3px;
  transition: background-color .5s ease;

  &:first-child {
    border-top: 1px solid $grey-4;
  }

  &.active {
    .brand-name,
    .brand-price {
      @apply text-white;
    }
  }

  .brand-name {
    margin-bottom: 13px;
    margin-top: 13px;
  }

  .brand-price {
    line-height: 31px;
    margin-bottom: 13px;
    padding-left: 17px;
    padding-right: 17px;
  }

  &.rco {
    &:hover {
      background-color: rgba(0,225,186,0.5);
    }

    &.active {
      @apply bg-green;
    }
  }

  &.smithCult {
    &:hover {
      background-color: rgba(248,72,118,0.5);
    }

    &.active {
      @apply bg-red;
    }
  }

  &.vaughn {
    &:hover {
      background-color: rgba(243,242,129,0.5);
    }

    &.active {
      @apply bg-yellow;
    }
  }
}

.selected-items {
  line-height: 35px;
  padding: 8px 0;
  text-indent: 26px;
}

.show-items-button,
.checkout-button {
  text-align: left;
  padding: 17px 25px;
  widows: 50%;

  &::v-deep {
    span {
      i {
        top: 18px;
        right: 22px;
      }
    }
  }
}

.show-items-button {
  @apply bg-brown-2;
}

.pro_cart_view {
  background-image: url('/assets/pattern-tile.png');
  background-repeat: repeat;
  background-position: left top;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease;

  &.active {
    transform: translateY(0);
  }

  .pro_cart_brand {
    padding: 13px 0 13px 20px;
  }

  .pro_cart_item {
    border-bottom: solid 1px $grey;
    min-height: 70px;
    padding: 23px 20px;

    &:last-child {
      border-bottom: none;
    }
  }

  .pro_cart_img {
    width: 50px;
  }

  .pro_cart_desc {
    width: 180px;
  }

  .btn-remove {
    top: 23px;
    right: 20px;
    z-index: 2;
    transition: color 0.25s ease;
  }

  .pro_cart_qty {
    width: 30px;
  }

  .pro_cart_input_qty {
    &::v-deep {
      input {
        @apply border-none bg-white text-h5 leading-none py-0 text-center relative z-1;
        width: 30px;
        height: 30px;
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }
}
</style>
