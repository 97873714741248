<template>
  <modal
    name="modal-redeem-points"
    class="modal-redeem-points"
  >
    <div slot="header">
      <div class="text-center uppercase tracking-xl leading-h2 text-h5 font-bold">
        {{ $t('Awesome!') }}
      </div>
    </div>
    <div slot="content">
      <div class="popup-title font-black tracking-lg leading-none uppercase text-center text-black">
        {{ $t('You\'ve earned points') }}
      </div>
      <div class="popup-subtitle italic text-center text-black font-serif">
        {{ $t('The more you spend, the more points you earn to redeem for products! Discover your new point balances.* Hover over to show earnings.') }}
      </div>
      <div class="popup-content mt-5 mb-md bg-black">
        <div class="loyalty">
          <earned-points-balance :is-account-menu="false" />
        </div>
        <div class="text-grey-2 tracking-normal mt-5 text-h4 leading-base">
          {{ $t('*Point values shown reflect the items in your current order. Altering your current order will impact the amount of points earned.') }}
        </div>
      </div>
      <div class="redeem-actions text-center sm:flex sm:justify-between">
        <button-full
          class="popup-button flex-grow mt-4 sm:mt-0 sm:mr-3.5 pr-5 pl-10"
          @click.native="goToRewardsCart()"
        >
          <span>
            {{ $t('Redeem points now') }}
          </span>
          <i class="ss-gizmo checkout-icon ss-right text-h5 px-2"></i>
        </button-full>
        <button-full
          class="popup-button flex-grow mt-4 sm:mt-0 sm:ml-3.5 pr-5 pl-10"
          @click.native="goToCheckout()"
        >
          <span>
            {{ $t('Continue to checkout') }}
          </span>
          <i class="ss-gizmo checkout-icon ss-right text-h5 px-2"></i>
        </button-full>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from 'theme/components/core/Modal'
import EarnedPointsBalance from 'theme/components/theme/EarnedPointsBalance'
import ButtonFull from 'theme/components/theme/ButtonFull'

export default {
  components: {
    Modal,
    EarnedPointsBalance,
    ButtonFull
  },
  methods: {
    goToRewardsCart () {
      this.$router.push({ name: 'rewards-cart' })
      this.$store.commit('ui/setOverlay', false)
    },
    goToCheckout () {
      this.$router.push({ name: 'checkout' })
      this.$store.commit('ui/setOverlay', false)
    }
  }
}
</script>

<style lang="scss" scoped>
$color-secondary: #808080;
$color-green: #00e1ba;
$color-white: #fff;
$color-red: #f84876;
$color-yellow: #f3f281;

.popup-title {
  font-size: 50px;
  margin-bottom: 7px;
}

.popup-subtitle {
  font-size: 21px;
  line-height: 22px;
}

.popup-content {
  @apply p-3;

  @screen md {
    padding: 40px 77px;
  }

  .loyalty {
    @apply relative w-full h-auto;
  }
}

.popup-button {
  padding-bottom: 18px;
  padding-top: 18px;

  &::v-deep {
    span {
      @apply flex justify-between items-center;
    }
  }
}

.header-loyalty-chart {
  .main-title-container {
    float: left;
    height: 34px;
    width: 166px;
  }

  .section-title-container {
    height: 34px;
    text-indent: 24px;
    width: 117px;
  }

  .points-title-container {
    height: 34px;
    text-indent: 14px;
    width: 88px;
  }

  .section-title,
  .points-title,
  .main-title {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .loyalty-brand {
    border-left: 1px solid $color-secondary;

    &:last-child {
      border-bottom: 1px solid rgba(128, 128, 128, .43);
    }

    &.brand-rco {
      border-left: 1px solid $color-green;

      .brand-name {
        border-top: 1px solid $color-green;
      }

      .points-rewards {
        border-left: 1px solid $color-green;

        .reward-amount {
          &:after {
            border-bottom: 5px solid $color-green;
          }
        }
      }
    }

    &.brand-smith-cult {
      border-left: 1px solid $color-red;

      .brand-name {
        border-top: 1px solid $color-red;
      }

      .points-rewards {
        border-left: 1px solid $color-red;

        .reward-amount {
          &:after {
            border-bottom: 5px solid $color-red;
          }
        }
      }
    }

    &.brand-vaughn {
      border-left: 1px solid $color-yellow;

      .brand-name {
        border-top: 1px solid $color-yellow;
      }

      .points-rewards {
        border-left: 1px solid $color-yellow;

        .reward-amount {
          &:after {
            border-bottom: 5px solid $color-yellow;
          }
        }
      }
    }

    .brand-name {
      border-top: 1px solid $color-secondary;
      width: 164px;
    }

    .brand-name-title {
      right: 20px;
      top: 10px;
    }

    .points-rewards {
      border-left: 1px solid rgba(128, 128, 128, .43);
      border-right: 1px solid rgba(128, 128, 128, .43);
      border-top: 1px solid rgba(128, 128, 128, .43);
      width: 203px;

      .reward-block {
        border-bottom: 1px solid rgba(128, 128, 128, .43);

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          .reward-hover-overlay {
            transform: translate(93%, -75%);
            opacity: 1;
          }
        }
      }

      .reward-type {
        width: 132px;
        padding: 9px 0;
        text-indent: 24px;
      }

      .reward-amount {
        width: 68px;
        padding: 9px 0;

        &:after {
          @apply block absolute;
          content: '';
          top: 50%;
          transform: translate(0, -50%);
          right: 6px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid $color-white;
        }
      }

      .reward-hover-overlay {
        top: 0;
        right: 0;
        transform: translate(93%, -85%);
        box-shadow: 2px 4px 19px rgba(0,0,0,0.3);
        padding: 10px 12px;
        pointer-events: none;
        opacity: 0;
        transition: all .3s linear 0s;

        .point-value {
          line-height: 30px;
        }

        .point-type {
          max-width: 180px;
        }
      }
    }
  }
}

.modal-redeem-points {
  &::v-deep {
    .modal-container-wrapper {
      @apply w-full p-5;

      @screen md {
        @apply p-0;
      }
    }

    .modal-container {
      @apply w-full;
      overflow: inherit;

      @screen md {
        @apply mx-auto;
        width: 530px;
      }

      .loyalty {
        @apply border-0 p-0;
      }
    }
  }
}
</style>
