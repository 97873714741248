<template>
  <modal
    name="modal-shipping-location"
    class="modal-shipping-location"
  >
    <div slot="header">
      <div class="text-center uppercase tracking-xl leading-h2 text-h5 font-bold">
        {{ $t('Warning!') }}
      </div>
    </div>
    <div slot="content">
      <div class="popup-subtitle italic text-above-h2 text-center text-black font-serif leading-lg my-4 sm:my-8">
        {{ $t('If you change shipping location the products in cart might also change') }}
      </div>
      <div class="text-center pt-4">
        <button-full
          class="popup-button mt-4 sm:mt-0 sm:ml-3.5 pr-5 pl-10"
          @click.native="setShippingLocation"
        >
          <span>
            {{ $t('Sure') }}
          </span>
        </button-full>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from 'theme/components/core/Modal'
  import ButtonFull from 'theme/components/theme/ButtonFull'

  export default {
    name: 'ShippingLocationPopup',
    components: {
      Modal,
      ButtonFull
    },
    data () {
      return {
        location: {}
      }
    },
    beforeMount () {
      this.$bus.$on('shipping-location-changed', this.changedLocation)
    },
    methods: {
      async setShippingLocation () {
        await this.$store.dispatch('storedCards/setCustomerLocation', { location: this.location })
        this.closeModal()
      },
      closeModal () {
        this.$bus.$emit('modal-hide', 'modal-shipping-location')
        this.$store.commit('ui/setOverlay', false)
      },
      changedLocation (location) {
        this.location = location
      }
    },
    beforeDestroy () {
      this.$bus.$off('shipping-location-changed', this.changedLocation)
    }
  }
</script>

<style lang="scss" scoped>
  .popup-content {
    @apply p-3;

    @screen md {
      padding: 40px 77px;
    }

    .loyalty {
      @apply relative w-full h-auto;
    }
  }

  .modal-shipping-location {
    &::v-deep {
      .modal-container-wrapper {
        @apply w-full p-5;

        @screen md {
          @apply p-0;
        }
      }

      .modal-container {
        @apply w-full;
        overflow: inherit;

        @screen md {
          @apply mx-auto;
          width: 530px;
        }
      }

      .popup-button {
        @apply px-4 py-3;
        min-width: 140px;

        span {
          @apply text-md font-bold;
        }
      }
    }
  }
</style>
