<template>
  <div @click="productQuickView"
       class="cursor-pointer product-tile pb-10 relative flex flex-col justify-start items-center text-center hover:bg-grey-18 hover:shadow-inner"
       v-observe-visibility="visibilityChanged"
  >
    <div class="product-tile__image-wrapper">
      <img
        :alt="product.name"
        :src="thumbnailObj.src"
        v-lazy="thumbnailObj"
        data-testid="productImage"
        class="product-tile__image"
      >
    </div>

    <div class="mt-6 lg:mt-2 px-4">
      <h2 class="text-base leading-h6 font-normal">
        {{ product.name | htmlDecode }}
      </h2>

      <div class="text-dark text-above-md italic leading-none font-serif mt-xs">
        <span v-if="!rulePrice">
          {{ getRedemptionPrice }}
        </span>
        <span v-else>
          {{ rulePrice }}
        </span>
        <span>{{ $t('Loyalty Points') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import ProductImageScaling from 'theme/mixins/blocks/ProductImageScaling'
import PriceRules from 'theme/mixins/blocks/PriceRules'

export default {
  mixins: [
    ProductTile,
    ProductImageScaling,
    PriceRules
  ],
  data () {
    return {
      thumbnailObjTest: {
        src: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/r/c/rco_pro_thumb__highdrive.png'
      },
      addToCart: 'white',
      rulePrice: null
    }
  },
  props: {
    productId: {
      type: [Number, String],
      required: true
    },
    labelsActive: {
      type: Boolean,
      requred: false,
      default: true
    },
    onlyImage: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      requred: false,
      default: 'primary'
    }
  },
  computed: {
    getRedemptionPrice () {
      let price = this.product.point_redemption_price || 0
      return price.toFixed(2)
    }
  },
  methods: {
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (isVisible) {
        if (rootStore.state.config.products.configurableChildrenStockPrefetchDynamic && rootStore.products.filterUnavailableVariants) {
          const skus = [this.product.sku]

          if (this.product.type_id === 'configurable' && this.product.configurable_children && this.product.configurable_children.length > 0) {
            for (const confChild of this.product.configurable_children) {
              const cachedItem = rootStore.state.stock.cache[confChild.id]

              if (typeof cachedItem === 'undefined' || cachedItem === null) {
                skus.push(confChild.sku)
              }
            }
            if (skus.length > 0) {
              rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
            }
          }
        }
      }
    },
    productQuickView () {
      this.$bus.$emit('rewards-product-quickView', this.productId)
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)

    this.rulePrice = this.getRulePrice(this.product)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
.product-tile {
  $block: &;
  transition: background-color .1s;
  height: 100%;
  @screen lg {
    overflow: hidden;
  }

  &__add-tocart-box {
    @screen lg {
      bottom: -61px;
      position: absolute;
      transition: bottom 0.25s ease;
    }
  }

  &:hover {
    background-image: linear-gradient(to bottom, #d7d7d7 0%, #eee 100%);
    #{$block}__add-tocart-box {
      @screen lg {
        bottom: 15px;
      }
    }
  }

  &__image-wrapper {
    @apply flex items-end;

    @screen sm {
      height: 320px;
    }
  }

  &__image {
    @apply block;
    mix-blend-mode: darken;
    max-width: 100%;
  }

  &__description {
    height: 120px;

    @screen lg {
      min-height: 105px;
    }
  }
}
</style>
