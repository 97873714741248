import { MicrocartProduct } from '@vue-storefront/core/modules/cart/components/Product.ts'
import i18n from '@vue-storefront/i18n'
import config from 'config'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import { getProductQtyIncrement } from 'theme/helpers/getProductQtyIncrement'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import {currentStoreView} from '@vue-storefront/core/lib/multistore'
import { mapGetters } from 'vuex'
import { clearTimeout, setTimeout } from 'timers'
import { productThumbnailPath } from '@vue-storefront/core/helpers'

export default {
  data () {
    return {
      rawQty: 0,
      incrementStep: 1,
      isLoading: true,
      isLoyalty: false,
      rulePrice: false,
      loadingTimeout: null,
      loadingTimeout2: null,
      standardVisible: false
    }
  },
  beforeMount () {
    this.incrementStep = getProductQtyIncrement(this.product)
    this.rulePrice = this.getRulePrice(this.product)

    // deprecated, will be moved to theme or removed in the near future #1742
    this.$bus.$on('cart-before-update', this.onProductBeforeUpdate)
    this.$bus.$on('cart-after-itemchanged', this.onProductChanged)
    this.$bus.$on('notification-after-itemremoved', this.onProductRemoved)
  },
  beforeDestroy () {
    // deprecated, will be moved to theme or removed in the near future #1742
    this.$bus.$off('cart-before-update', this.onProductBeforeUpdate)
    this.$bus.$off('cart-after-itemchanged', this.onProductChanged)
    this.$bus.$off('notification-after-itemremoved', this.onProductRemoved)
    this.$bus.$off(`cart_${this.product.sku}_updated`, this.itemUpdated)
  },
  mounted () {
    this.$bus.$on(`cart_${this.product.sku}_updated`, this.itemUpdated)
    this.$bus.$emit(`cart_${this.product.sku}_updated`)
  },
  computed: {
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      loyaltyProductsInCart: 'cart/getLoyaltyCartItems',
      attributesByCode: 'attribute/attributeListByCode',
      attributesById: 'attribute/attributeListById'
    }),
    thumbnail () {
      const thumbnail = productThumbnailPath(this.product) || productThumbnailPath(this.product.parentProduct || {}, true)

      if (typeof navigator !== 'undefined' && !navigator.onLine) {
        return this.getThumbnail(thumbnail, config.products.thumbnails.width, config.products.thumbnails.height) // for offline support we do need to have ProductTile version
      } else return this.getThumbnail(thumbnail, config.cart.thumbnails.width, config.cart.thumbnails.height)
    },
    isStandardItem () {
      let qty = this.product.qty - (((this.product.product_option || {}).extension_attributes || {}).qty_using_points || 0)

      this.$nextTick(() => {
        this.standardVisible = qty > 0
      })

      return qty > 0
    },
    isLoyaltyItem () {
      return this.loyaltyProductsInCart.find(x => x.sku === this.product.sku)
    },
    getLoyaltyQuantity () {
      return ((this.product.product_option || {}).extension_attributes || {}).qty_using_points || 0
    },
    getQuantity () {
      return this.product.qty - this.getLoyaltyQuantity
    },
    isInStock () {
      // anything outside of boolean false is treated as true.
      return this.product.is_in_stock !== false
    },
    configurableProductInfo () {
      if (!['configurable', 'virtual', 'simple'].includes(this.product.type_id)) {
        return false
      }

      if (!this.product.parentProduct && !(this.product.parentProduct || {}).configurable_options) {
        return false
      }

      let options = ((this.product.product_option || {}).extension_attributes || {}).configurable_item_options || []

      if (!options.length) {
        return false
      }

      let configurableOptions = this.product.parentProduct.configurable_options || []

      let configurableOption = configurableOptions.find(x => String(x.attribute_id) === String(options[0].option_id))

      if (!configurableOption) return false

      let data = configurableOption.values.find(x => String(x.value_index) === String(options[0].option_value)) || {}

      return {
        label: configurableOption.label,
        value: data.label
      }
    }
  },
  watch: {
    product: {
      handler () {
        this.itemUpdated()
      },
      deep: true
    }
  },
  methods: {
    usingPoints (items) {
      return !!(items.filter(item => ((item.product_option || {}).extension_attributes || {}).qty_using_points > 0) || []).length
    },
    itemUpdated () {
      this.$nextTick(() => {
        let loyaltyQty = ((this.product.product_option || {}).extension_attributes || {}).qty_using_points || 0
        let qty = this.product.qty - loyaltyQty

        this.rawQty = this.isLoyalty ? loyaltyQty : qty
        this.isLoading = false
      })
    },
    setLoading (v) {
      this.isLoading = v
    },
    setRawQuantity (v) {
      this.rawQty = v
    },
    removeItem () {
      if (config.cart.askBeforeRemoveProduct) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          item: this.product,
          message: i18n.t('Are you sure you would like to remove this item from the shopping cart?'),
          action2: { label: i18n.t('OK'), action: this.removeFromCart },
          action1: { label: i18n.t('Cancel'), action: 'close' },
          hasNoTimeout: true
        })
      } else {
        this.removeFromCart()
      }
    },
    removeFromCart () {
      this.isLoading = true
      let sku = this.product.sku

      if (this.isLoyalty) {
        this.$store.dispatch('cart/removeLoyaltyItem', { product: this.product }).then(() => {
          this.$bus.$emit(`cart_${sku}_updated`)
        })
        return
      }

      if (this.getLoyaltyQuantity !== 0) {
        this.$store.dispatch('cart/updateQuantity', {product: this.product, qty: this.getLoyaltyQuantity})
      } else {
        this.$store.dispatch('cart/removeItem', {product: this.product}).then(() => {
          this.$bus.$emit(`cart_${sku}_updated`)
        })
      }
    },
    updateQuantity (newQuantity) {
      this.isLoading = true
      if (this.isLoyalty) {
        if (newQuantity && newQuantity % this.incrementStep === 0) {
          this.$store.dispatch('cart/upsertLoyaltyItem', { product: this.product, qty: newQuantity }).then(() => {
            this.$bus.$emit(`cart_${this.product.sku}_updated`)
          })
        }
      } else {
        if (newQuantity && newQuantity % this.incrementStep === 0) {
          let qty = newQuantity + this.getLoyaltyQuantity

          this.$store.dispatch('cart/cartAddItem', {productToAdd: this.product, qty: qty, update: true}).then(() => {
            this.$bus.$emit(`cart_${this.product.sku}_updated`)
          })
        }
      }

      if (parseInt(newQuantity, 10) === 0) {
        this.removeItem()
      }
    },
    onProductBeforeUpdate (event) {
      if (!this.$isServer) {
        clearTimeout(this.loadingTimeout2)
        this.loadingTimeout2 = setTimeout(() => {
          this.isLoading = false
        }, 5000)
      }
    },
    onProductChanged (event) {
      let product = event.item || event.product || {}
      // deprecated, will be moved to theme or removed in the near future #1742

      if (product.sku === this.product.sku) {
        if (!this.$isServer) {
          clearTimeout(this.loadingTimeout)
          this.loadingTimeout = setTimeout(() => {
            this.isLoading = false
          }, 1000)
        }
        this.$forceUpdate()
      }
    },
    onProductRemoved (event) {
      if (event.item.sku === this.product.sku) {
        this.removeFromCart(event.item)
      }
    },
    productLink (product) {
      if (product.type_id === 'virtual' && product.parentProduct) {
        return formatProductLink(product.parentProduct, currentStoreView().storeCode)
      }

      return formatProductLink(product, currentStoreView().storeCode)
    }
  },
  mixins: [
    MicrocartProduct,
    PriceRules
  ]
}
