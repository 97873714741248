<template>
  <base-select
    name="shipping-location"
    class="shipping-location mb-sm"
    v-model="selectedOption"
    @change.native="openShippingLocationPopup($event)"
    :options="getShippingLocations"
    :selected="selectedOption"
  >
    {{ $t('Shipping location:') }}
  </base-select>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import mapValues from 'lodash-es/mapValues'
import groupBy from 'lodash-es/groupBy'

export default {
  name: 'ShippingLocation',
  components: { BaseSelect },
  data () {
    return {
      shippingLocations: {
        1: {
          id: 1,
          value: '',
          locationId: '',
          label: 'R+Co Location',
          enabled: false
        },
        2: {
          id: 2,
          value: '',
          locationId: '',
          label: 'V76 Location',
          enabled: false
        },
        3: {
          id: 3,
          value: '',
          locationId: '',
          label: 'Smith & Cult Location',
          enabled: false
        },
        4: {
          id: 4,
          value: '',
          locationId: '',
          label: 'Education Location',
          enabled: false
        }
      },
      selectedOption: ''
    }
  },
  async beforeMount () {
    let location = this.getShippingLocations || []

    if (location.length) {
      const initialLocation = this.getShippingLocations[0]

      this.selectedOption = `${initialLocation.value}`
      // if (this.location) return
      this.setShippingLocation(initialLocation)
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current,
      shipping: state => state.checkout.shippingDetails
    }),
    ...mapGetters({
      location: 'storedCards/getLocation'
    }),
    getShippingLocations () {
      let userShippingLocations = this.currentUser ? this.currentUser.extension_attributes.locations_data : []

      // group by location id
      userShippingLocations = mapValues(groupBy(userShippingLocations, 'address_id'), list => {
        let loc = list[0]
        return {
          addressId: loc.address_id,
          brandId: loc.brand_id,
          locationId: loc.location_id,
          value: `${loc.location_id}`,
          label: loc.nick_name,
          enabled: loc.status
        }
      })

      this.shippingLocations = Object.values(userShippingLocations).filter(location => location.enabled === true)

      return this.shippingLocations
    }
  },
  methods: {
    openShippingLocationPopup (event) {
      if (event.target.value) this.$bus.$emit('modal-show', 'modal-shipping-location')
    },
    setShippingLocation (location) {
      this.$store.dispatch('storedCards/setCustomerLocation', { location: location })
    }
  },
  watch: {
    selectedOption: {
      immediate: true,
      handler: function (newVal) {
        if (newVal === 'default_shipping' || !newVal) return
        let currentLocation = this.getShippingLocations.find(location => location.value == newVal)

        this.$bus.$emit('shipping-location-changed', currentLocation)
      }
    }
  }
}
</script>
