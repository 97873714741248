<template>
  <table class="pro_checkout_items table">
    <thead>
    <tr>
      <th class="checkout_item_img">{{ $t('Item') }}</th>
      <th class="checkout_item_name"></th>
      <th class="checkout_item_price">{{ $t('Price') }}</th>
      <th class="checkout_item_qty">{{ $t('Qty') }}</th>
      <th class="checkout_item_subtotal">{{ $t('Subtotal') }}</th>
    </tr>
    </thead>
    <tbody v-if="productsInCart.length">
      <template v-for="(product) in productsInCart">
        <product-details :product="product" :key="uid(product)"/>
        <product-details-loyalty :product="product" :key="uid(product) + 'loyalty'"/>
      </template>
    </tbody>
  </table>
</template>

<script>
import ProductDetails from './ProductDetails'
import ProductDetailsLoyalty from './ProductDetailsLoyalty'

export default {
  components: {ProductDetailsLoyalty, ProductDetails},
  props: {
    productsInCart: {
      type: null,
      required: true
    }
  },
  methods: {
    uid (e) {
      if (e.uid) return e.uid

      const key = Math.random()
        .toString(16)
        .slice(2)

      this.$set(e, 'uid', key)

      return e.uid
    }
  }
}
</script>

<style lang="scss">
  .pro_checkout_items {
    .checkout_item_remove {
      @apply text-center;
    }
  }
</style>
