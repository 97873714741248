<template>
  <div class="relative">
    <div class="sidebar" id="affix">
      <div class="h-full relative overflow-y-auto">
        <transition name="slidedown">
          <div v-if="!showLoyaltyCart" key="brands">
            <div class="px-7 py-11">
              <h2 class="m-0 font-serif font-black text-h2 leading-none text-dark capitalize">{{ $t('Redeem Your Points') }}</h2>
            </div>

            <ul class="border-t border-black border-solid">
              <li
                v-for="(brand, key) in brands"
                :key="key"
                class="relative overflow-visible border-b border-black border-solid cursor-pointer"
              >
                <div v-if="brand.hasPermission" class="brand-list-item px-7 pt-4 pb-3" @click="selectBrand(brand)" :class="[brand.code, {'active': selectedBrand === brand.id}]">
                  <span class="text-dark tracking-average font-bold block leading-none text-heading-page uppercase mb-3">
                    {{ brand.name }}
                  </span>
                  <span class="block font-black text-h6 tracking-md uppercase text-brown-2 px-4 leading-h3">
                    <span>{{ getFullBalance(brand) }}</span>
                    <span>{{ $t('points') }}</span>
                  </span>
                </div>
                <ul v-if="brand.subBrands && Object.keys(brand.subBrands).length" class="p-0 border-t border-black border-solid">
                  <li
                    v-if="brand.subBrands && Object.keys(brand.subBrands).length"
                    v-for="(subBrand, key) in brand.subBrands"
                    :key="key"
                    class="relative overflow-visible border-solid cursor-pointer"
                  >
                    <div @click="selectBrand(subBrand)" class="brand-list-item pl-12 pt-4 pb-3" :class="[subBrand.code, {'active': selectedBrand === subBrand.brand_id}]">
                      <span class="text-dark tracking-average font-bold block leading-none text-heading-page uppercase mb-3">
                        {{ subBrand.name }}
                      </span>
                      <span class="block font-black text-h6 tracking-md uppercase text-brown-2 px-4 leading-h3">
                        <span>See {{ brand.name }} points</span>
                      </span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </transition>
        <transition name="slideup">
          <loyalty-cart v-if="showLoyaltyCart" key="cart" />
        </transition>

        <div class="sidebar_bottom fixed z-2 w-full left-0 bottom-0">
          <div class="z-2 relative bg-grey-7">
            <div class="text-average px-6 py-3">
              <span>{{ productsInCart.length }}</span>
              <span>{{ $t(`item${productsInCart.length === 1 ? '' : 's' } selected`) }}</span>
            </div>
            <div class="flex">
              <button-full class="show-items-button" @click.native="showLoyaltyCart = !showLoyaltyCart">
                <span>{{ $t('Show items') }}</span>
                <i class="ss-gizmo checkout-icon ss-up absolute text-h5" :class="[showLoyaltyCart ? 'ss-down' : 'ss-up']"></i>
              </button-full>
              <button-full
                class="checkout-button"
                :loading="checkoutLoading"
                @click.native="goToCheckout()"
              >
                <span>{{ $t('Complete') }}</span>
                <i class="ss-gizmo checkout-icon ss-right absolute text-h5"></i>
              </button-full>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="redeem-points__container">
      <div>
        <product-listing columns="5" v-for="(item, key) in productsByCategories" :products="item.products" :key="key" class="lg:py-xl px-sm">
          <template #callout>
            <div class="text-black lg:text-xl font-black font-serif lg:leading-h2 product-listing__subheader text-lg leading-none lg:my-0 my-9.5 text-center">
              {{ item.categoryName }}
            </div>
          </template>
        </product-listing>
      </div>
    </div>
    <redeem-points-quick-view v-if="quickViewProduct" :product="quickViewProduct" :brands="brands" />
  </div>
</template>

<script>
import config from 'config'
import { mapGetters } from 'vuex'
import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'
import Composite from '@vue-storefront/core/mixins/composite'
import { Logger } from '@vue-storefront/core/lib/logger'

import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'
import ProductListing from 'theme/components/core/blocks/Rewards/ProductListing'
import ButtonFull from 'theme/components/theme/ButtonFull'
import RedeemPointsQuickView from 'theme/components/core/blocks/Checkout/RedeemPointsQuickView'
import LoyaltyCart from 'theme/components/core/blocks/Rewards/LoyaltyCart'
import i18n from '@vue-storefront/i18n'

let scrolling = false
const onScroll = () => {
  scrolling = true
}

export default {
  name: 'RewardsCart',
  components: {
    ProductListing,
    ButtonFull,
    RedeemPointsQuickView,
    LoyaltyCart
  },
  mixins: [
    Composite,
    EarnedPoints
  ],
  data () {
    return {
      checkoutLoading: false,
      pagination: {
        perPage: 1000,
        current: 0,
        enabled: false
      },
      lazyLoadProductsOnscroll: false,
      selectedBrand: 0,
      brands: {},
      quickViewProduct: null,
      showLoyaltyCart: false,
      scrollInterval: null,
      tabletPoint: 768
    }
  },
  computed: {
    ...mapGetters({
      getBrands: 'brands/getBrands'
    }),
    products () {
      return this.$store.state.product.list.items
    },
    productsCounter () {
      return this.products ? this.products.length : 0
    },
    productsTotal () {
      return this.$store.state.product.list.total
    },
    categories () {
      return this.$store.state.category.list
    },
    productsByCategories () {
      if (this.productsCounter < 1) {
        return []
      }

      let productsByCategories = []

      for (let i = 0; i < this.categories.length; i++) {
        const category = this.categories[i]

        if (category.level <= 3) continue

        const products = this.products.filter(product => {
          return product.hasOwnProperty('category_ids')
            && product.category_ids.includes(category.id)
            && product.point_can_spend
        })

        products.sort((a, b) => {
          let first = a.category.find(cat => cat.category_id === category.id)
          let second =  b.category.find(cat => cat.category_id === category.id)
          return first.position - second.position
        })

        if (products.length > 0) {
          productsByCategories.push({
            categoryId: category.id,
            categoryName: category.name,
            products
          })
        }
      }

      return productsByCategories
    },
    productsInCart () {
      return this.$store.getters['cart/getLoyaltyCartItems']
    },
    allProductsInCart () {
      return this.$store.getters['cart/getCartItems']
    },
    getChildBrands () {
      return this.getBrands.filter(brand => brand['parent_id'])
    }
  },
  async asyncData ({ store, route, context }) { // this is for SSR purposes to prefetch data
    Logger.info('Entering asyncData in Rewards Cart Page')()

    try {
      await store.dispatch('category/list', {
        skipCache: true,
        includeFields: config.entities.optimize ? config.entities.category.includeFields : null
      })

      let productQuery = prepareQuery({ filters: [
        { key: 'brand_id', value: { 'eq': 1 } },
        { key: 'point_can_spend', value: { 'eq': 1 } }
      ] })

      await store.dispatch('product/list', {
        query: productQuery,
        size: 1000,
        includeFields: config.entities.optimize ? config.entities.productList.includeFields : null,
        updateState: true
      })
    } catch (err) {
      Logger.error(err)()
      throw err
    }
  },
  beforeMount () {
    this.$bus.$on('rewards-product-quickView', this.productQuickView)
    this.$bus.$on('cart-after-updatetotals', this.onCartAfterUpdate)
    window.addEventListener('scroll', onScroll, { passive: true })

    this.scrollInterval = setInterval(() => {
      if (scrolling) {
        if (window.scrollY <= 100 && document.documentElement.clientWidth > 767) {
          document.getElementById('affix').style.top = 100 - window.scrollY + 'px'
          document.getElementById('affix').style.height = 'calc(100vh - ' + (108 + 100 - window.scrollY) + 'px)'
        } else {
          document.getElementById('affix').style.top = '0'
          document.getElementById('affix').style.height = 'calc(100vh - 108px)'
        }
        scrolling = false
      }
    }, 16)
  },
  beforeDestroy () {
    this.$bus.$off('rewards-product-quickView', this.productQuickView)
    this.$bus.$off('cart-after-updatetotals', this.onCartAfterUpdate)

    window.removeEventListener('scroll', onScroll, { passive: true })
    clearInterval(this.scrollInterval)
  },
  async mounted () {
    this.brands = await this.getRewards()

    // important, ignore the mess
    let availableBrands = []
    for (let brand in this.brands) {
      if (this.brands[brand].hasPermission) {
        availableBrands.push(this.brands[brand].brand_id)
      }
      for (let subBrand in this.brands[brand].subBrands) {
        if (this.brands[brand].subBrands[subBrand].hasPermission) {
          availableBrands.push(this.brands[brand].subBrands[subBrand].brand_id)
        }
      }
    }

    this.selectedBrand = availableBrands[0] || this.selectedBrand

    await this.loadCategories()
    await this.loadProducts()
    // this.$store.dispatch('loyalty/loadCart')

    if (document.documentElement.clientWidth >= this.tabletPoint) {
      document.getElementById('affix').style.top = 100 - window.scrollY + 'px'
      document.getElementById('affix').style.height = 'calc(100vh - ' + (108 + 100 - window.scrollY) + 'px)'
    }
  },
  methods: {
    async loadCategories () {
      return this.$store.dispatch('category/list', {
        skipCache: true,
        includeFields: this.$store.state.config.entities.optimize ? this.$store.state.config.entities.category.includeFields : null
      })
    },
    async loadProducts () {
      let productQuery = prepareQuery({ filters: [
        { key: 'brand_id', value: { 'eq': this.selectedBrand } },
        { key: 'point_can_spend', value: { 'eq': 1 } }
      ] })

      await this.$store.dispatch('product/list', {
        query: productQuery,
        size: 1000,
        includeFields: this.$store.state.config.entities.optimize ? this.$store.state.config.entities.productList.includeFields : null,
        updateState: true
      })
    },
    selectBrand (brand) {
      this.selectedBrand = brand.brand_id
      this.loadProducts()
    },
    productQuickView (productId) {
      for (let i = 0; i < this.products.length; i++) {
        const product = this.products[i]

        if (product.id === productId) {
          this.quickViewProduct = product
          break
        }
      }

      this.$nextTick(() => {
        this.$bus.$emit('modal-show', 'modal-points-quick-view')
      })
    },
    getFullBalance (brand) {
      return this.getEarnedBalance(brand) - this.getSpentBalance(brand)
    },
    getEarnedBalance (brand) {
      return brand.earnedPoints
        ? parseFloat(brand.balance + brand.earnedPoints).toFixed(2)
        : (brand.balance || 0.0).toFixed(2)
    },
    getSpentBalance (brand) {
      let balance = 0
      this.cartItems.filter(x => x.brand_id === brand.id).forEach(item => {
        let rewardData = ((item.extension_attributes || {}).reward_data || {})

        let qty = rewardData.quantity || 0
        let price = rewardData.price || 0
        balance += price * qty
      })
      return balance
    },
    async goToCheckout () {
      this.checkoutLoading = true

      await Promise.all(this.allProductsInCart.map(async (product) => {
        const stockResult = await this.$store.dispatch('stock/check', { product: product, qty: product.qty || 1 }, {root: true})
        // used to get the online check result
        product.onlineStockCheckid = stockResult.onlineCheckTaskId

        // check against min qty
        const calculatedQty = ((stockResult || {}).qty || 0) - (product.qty || 1)
        const isInStock = (calculatedQty > 0 && calculatedQty > (stockResult.min_qty || (product.stock || {}).min_qty))

        if (stockResult.status === 'out_of_stock' || !isInStock) {
          // remove item from cart
          // await this.$store.dispatch('cart/removeItem', { product: product }, {root: true})
          await this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: `${product.name} ` + i18n.t('is out of stock, please remove the item from your cart to continue.'),
            action1: { label: i18n.t('OK') }
          });
          throw new Error("Whoops!");
        }
      })).then(() => {
        this.checkoutLoading = false
        this.$router.push({ name: 'checkout' })
      }).catch(() => {
        this.checkoutLoading = false
      })

    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
    },
    async onCartAfterUpdate () {
      let brands = await this.getRewards()
 /*     const locations = this.currentUser ? this.currentUser.extension_attributes.locations_data : []

      this.getChildBrands.forEach(brand => {
        let parentBrandKey = Object.keys(brands)
          .find(key => brands[key]['id'] === brand['parent_id'])

        if (!parentBrandKey) return

        let hasPermission = locations.find(location => location.brand_id === brand.id && location.status === true)

        if (!hasPermission) return

        brands[brand.code] = {
          id: brand.id,
          name: brand.name,
          code: brand.code,
          balance: brands[parentBrandKey]['balance'],
          earnedPoints: brands[parentBrandKey]['earnedPoints'],
          color: brands[parentBrandKey]['color'],
          increase: brands[parentBrandKey]['increase']
        }
      })*/

      this.brands = brands
    }
  }
}
</script>

<style lang="scss" scoped>
$sidebar-width: 300px;
$rco-color: #00e1ba;
$rco-bleu-color: #0e3f6f;
$sc-color: #f84876;
$v76-color: #f3f281;
$elz-color: #cf9a7e;

.redeem-points {
  &__container {
    @apply relative w-full px-7 pt-7 pb-34;

    @screen md {
      margin: 0 auto;
      max-width: 1600px;
      padding: 0 60px 0 360px;
      min-height: 100vh;
    }

    @media screen and (min-width: 1900px) {
      width: 1180px;
      padding: 0;
    }
  }
}

.sidebar {
  background-color: #fff;
  background-image: url('/assets/pattern-tile.png');
  background-repeat: repeat;
  background-position: left top;
  z-index: 1;

  @screen md {
    @apply fixed;
    height: calc(100vh - 108px);
    left: 0;
    top: 100px;
    transition: top 0.05s linear;
    width: $sidebar-width;
  }

  &_bottom {
    @screen md {
      width: $sidebar-width;
    }

    &:before {
      @apply absolute rounded-100;
      content: '';
      width: 90%;
      height: 10px;
      top: 0;
      left: 5%;
      z-index: 0;
      box-shadow: 0 0 9px rgba(0, 0, 0, .65);
      border-radius: 100%;
      background-clip: padding-box;
    }
  }
}

.show-items-button,
.checkout-button {
  @apply relative text-left w-1/2 px-6 py-4;

  &::v-deep {
    span {
      i {
        top: 18px;
        right: 22px;
      }
    }
  }
}

.show-items-button {
  @apply bg-brown-2;
}

.brand-list-item {
  -webkit-transition: background-color .5s ease;
  -o-transition: background-color .5s ease;
  transition: background-color .5s ease;

  &.rco {
    &:hover {
      background-color: rgba($rco-color, .5);
    }

    &.active {
      background-color: $rco-color;
    }
  }

  &.rco_bleu {
    &:hover {
      background-color: rgba($rco-bleu-color, .5);
      span {
        color: #fff !important;
      }
    }

    &.active {
      background-color: $rco-bleu-color;
      span {
        color: #fff !important;
      }
    }
  }

  &.sc {
    &:hover {
      background-color: rgba($sc-color, .5);
    }

    &.active {
      background-color: $sc-color;
    }
  }

  &.v76 {
    &:hover {
      background-color: rgba($v76-color, .5);
    }

    &.active {
      background-color: $v76-color;
    }
  }

  &.elz {
    &:hover {
      background-color: rgba($elz-color, .5);
    }

    &.active {
      background-color: $elz-color;
    }
  }
}

.slidedown-enter-active, .slideup-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slidedown-leave-active, .slideup-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slidedown-enter-to, .slidedown-leave {
  max-height: 1000px;
  overflow: hidden;
}

.slidedown-enter, .slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}

.slideup-enter-to, .slideup-leave {
  transition: translateY(0);
  overflow: hidden;
}

.slideup-enter, .slideup-leave-to {
  overflow: hidden;
  transition: translateY(100%);
}
</style>
