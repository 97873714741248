// Config example
//
// "users": {
//   "groupIds": {
//     "5": {
//       "name": "Wholesale",
//       "factor": 1
//     },
//     "7": {
//       "name": "Wholesale -10%",
//       "factor": 1.1
//     },
//     "8": {
//       "name": "Wholesale -12.5%",
//       "factor": 1.125
//     },
//     "10": {
//       "name": "Wholesale -15%",
//       "factor": 1.15
//     }
//   }
// }

import { mapState } from 'vuex'
import config from 'config'

export default {
  name: 'ProfitsCalculate',
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    }),
    getGroupIds () {
      return config.users.groupIds
    },
    getFactor () {
      return (this.currentUser && this.getGroupIds.hasOwnProperty(this.currentUser.group_id)) ? this.getGroupIds[this.currentUser.group_id].factor : 1
    }
  }
}
