<template>
  <li class="product product-sidebar-item px-5 py-6 bg-grey-11 relative clearfix border-b">
    <div class="col product_image float-left">
      <img v-lazy="thumbnail" class="block" :alt="product.name">
    </div>
    <div class="col product_desc px-5 float-left">
      <div class="text-dark text-h4 block leading-base">
        {{ product.name }}
      </div>
      <div class="text-grey-2 text-h4 block leading-base" v-html="product.description" />
      <div class="text-grey-2 text-h4 block leading-base pt-sm uppercase">
        <span v-if="!rulePrice">
          {{(product.point_redemption_price || 0).toFixed(2) }}
        </span>
        <span v-else>
          {{ rulePrice }}
        </span>
        <span>{{ $t('PTS') }}</span>
      </div>
      <div class="btn-remove absolute inline-block p-0 text-grey-2 hover:text-black bg-transparent font-bold text-h5 leading-loose tracking-lg uppercase z-2 cursor-pointer">
        <i @click="removeFromCart" class="ss-icon ss-delete text-heading-page leading-none"></i>
      </div>
    </div>
    <div class="col qty pt-xl relative float-left inline-block">
      <base-input-number
        class="qty_input" type="text"
        :value="product.product_option.extension_attributes.qty_using_points"
        :min="incrementStep"
        :readonly="true"
        @blur="updateQuantity"
        @input="updateQuantity"
        :increment="incrementStep"
      />
    </div>
  </li>
</template>

<script>
import { MicrocartProduct } from '@vue-storefront/core/modules/cart/components/Product.ts'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseInputNumber from 'theme/components/core/blocks/Form/BaseInputNumber'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import { getProductQtyIncrement } from 'theme/helpers/getProductQtyIncrement'
import debounce from 'lodash-es/debounce'

export default {
  components: {
    BaseInputNumber
  },
  mixins: [
    MicrocartProduct,
    PriceRules
  ],
  data () {
    return {
      incrementStep: 1,
      rulePrice: null
    }
  },
  methods: {
    removeFromCart () {
      this.$store.dispatch('cart/removeLoyaltyItem', { product: this.product })
    },
    updateQuantity: debounce(function (quantity) {
      if (quantity && quantity % this.incrementStep === 0) {
        this.$store.dispatch('cart/upsertLoyaltyItem', { product: this.product, qty: quantity }).then(() => {

        })
      }
    }, 500, {
      leading: true
    }),
  },
  async beforeMount () {
    this.incrementStep = getProductQtyIncrement(this.product)

    this.rulePrice = this.getRulePrice(this.product)
  },
}
</script>

<style lang="scss">
.product-sidebar-item {
  min-height: 70px;

  &:last-child {
    border-bottom: none;
  }

  .product_image {
    width: 50px;

    img {
      max-height: 100px;
    }
  }

  .product_desc {
    width: 180px;
  }

  .btn-remove {
    top: 23px;
    right: 20px;
    z-index: 2;
    transition: color 0.25s ease;
  }

  .qty {
    width: 30px;
  }

  .quantity-select-action {
    input[type='number'] {
      padding: 0;
      text-align: center;
      border: none;
    }
    button {
      border: none;
      height: 26px;
      width: 26px;
      margin: 0 2px;
      padding-top: 6px;
      // display: none!important;
    }
  }

  .qty_input {
    &::v-deep {
      input {
        @apply border-none bg-white text-h5 leading-none py-0 text-center relative z-1;
        width: 30px;
        height: 30px;
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }
}
</style>
