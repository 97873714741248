<template>
  <div class="loyalty-points">
    <div class="loyalty-header">
      <div>
        <span>
          {{ $t('Loyalty Points') }}
        </span>
      </div>
      <div>
        <span>
          {{ $t('Points') }}
        </span>
      </div>
    </div>
    <div
      class="loyalty-brand"
      v-for="(reward, key) in rewardPoints"
      :key="key"
      :class="`brand-id-${reward.code}`"
      :style="{ borderLeftColor: `#${reward.color}` }"
      :data-brand="reward.code"
    >
      <div
        v-if="reward.hasPermission || reward.hasSubBrandPermission"
        class="brand-name"
        :data-bank-id="reward.bank_id"
        :data-account-id="reward.account_id"
        :style="{ borderColor: `#${reward.color}` }"
      >
        <span :style="{ color: `#${reward.color}` }">
          {{ reward.name }}
        </span>
      </div>
      <earned-points-block v-if="reward.hasPermission || reward.hasSubBrandPermission" :reward="reward" />
    </div>
  </div>
</template>

<script>
import EarnedPointsBlock from 'theme/components/theme/blocks/Checkout/EarnedPointsBlock'
import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'

export default {
  name: 'EarnedPointsBalance',
  components: {
    EarnedPointsBlock
  },
  mixins: [
    EarnedPoints
  ],
  props: {
    isAccountMenu: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      rewardPoints: []
    }
  },
  beforeMount () {
    this.$bus.$on('refresh-loyalty-points', this.refreshPoints)
  },
  methods: {
    async refreshPoints () {
      this.rewardPoints = await this.getRewards()
    }
  }
}
</script>

<style lang="scss" scoped>
  $color-green: #00e1ba;
  $color-yellow: #f3f281;
  $color-red: #f84876;
  $color-white: #ffffff;

  .loyalty-points {
    &__increase {
      @apply absolute block border-l-5 border-r-5 border-b-5 border-solid border-transparent z-10;
      right: 6px;
      top: 50%;
      transform: translate(0, -50%);
    }

    .loyalty-brand {
      &::v-deep {
        .loyalty-points__increase {
          border-bottom-color: $color-white;
        }

        .loyalty-points__increase-earned {
          @apply text-black;
        }
      }

      &.brand-id-rco {
        &::v-deep {
          .loyalty-points__increase {
            border-bottom-color: $color-green;
          }

          .loyalty-points__increase-earned {
            @apply text-green;
          }
        }
      }

      &.brand-id-v76 {
        &::v-deep {
          .loyalty-points__increase {
            border-bottom-color: $color-yellow;
          }

          .loyalty-points__increase-earned {
            @apply text-yellow;
          }
        }
      }

      &.brand-id-sc {
        &::v-deep {
          .loyalty-points__increase {
            border-bottom-color: $color-red;
          }

          .loyalty-points__increase-earned {
            @apply text-red;
          }
        }
      }
    }
  }
</style>
