<template>
  <div class="mb-sm relative flex items-center flex-col w-full" v-if="freeShippingThreshold > 0">
    <span class="w-full h-3 relative pt-2 mt-8" v-if="!hasFreeShipping">
      <svg id="pointer-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-360 280-560h400L480-360Z"/></svg>
      <span id="threshold-number" class="black">${{ freeShippingThreshold }}</span>
      <span class="bg-grey-6 w-full h-3 absolute"></span>
      <span id="fill-bar" class="bg-black w-full scale-x-0 h-3 absolute transition-transform" :style="freeShippingBarStyleObject"></span>
    </span>
    <span class="flex-1 text-dark tracking-mid mb-3 uppercase text-h6 leading-h3 font-sans inline-block text-center mt-2" v-if="!hasFreeShipping">
      <b>{{ freeShippingDifference | price }}</b> away from free shipping!
    </span>
    <span class="flex-1 text-dark tracking-mid uppercase text-h6 leading-h3 font-sans inline-block text-center" v-else>
      You've earned free shipping!
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FreeShippingBar',
  props: {
    subtotal: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    ...mapState({
      hasFreeShipping: state => state.cart.hasFreeShipping,
      freeShippingDifference: state => state.cart.freeShippingDifference,
      freeShippingThreshold: state => state.cart.freeShippingThreshold || -1
    }),
    freeShippingBarStyleObject () {
      return {
        clipPath: `inset(0 ${100 - this.clamp(0, 100, 100 * this.subtotal / this.freeShippingThreshold)}% 0 0)`
      }
    }
  },
  methods: {
    clamp (min, max, value) {
      return Math.min(Math.max(value, min), max)
    }
  }
}
</script>

<style scoped>
#fill-bar {
  transform-origin: left;
  transition-property: transform, background-color, clip-path;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  background: linear-gradient(to right, black, yellow);
}
#pointer-icon {
  position: absolute;
  transform: translateX(-50%);
  top: -9px;
  left: 100%;
}
#threshold-number {
  position: absolute;
  top: -20px;
  left: 99%;
  transform: translateX(-50%);
  font-size: 12px;
  color: black;
}
</style>
