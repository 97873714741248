<template>
  <div class="relative">
    <label
      v-if="hasDefaultSlot"
      :for="getInputId"
      class="label uppercase text-black text-h6 tracking-md leading-thick whitespace-no-wrap"
    >
      <slot />
    </label>
    <div class="inline-flex items-center justify-center quantity-select-action w-full">
      <input
        :id="getInputId"
        type="number"
        :name="name"
        :min="min"
        class="text-dark py-sm px-5 focus:outline-none focus:bg-grey-11 border border-solid border-dark text-average leading-sm tracking-xs w-full"
        :class="classes"
        :focus="autofocus"
        :value="value"
        :disabled="disabled"
        @input="inputEvent"
        @blur="blurEvent"
        @focus="$emit('focus')"
        @keyup.enter="keyupEnter"
        @keyup.up="incrementEvent"
        @keyup.down="decrementEvent"
        @keyup="keyUpEvent"
      >
      <button @click.prevent="decrementEvent" class="lg:inline-block border border-black border-solid m-0 bg-transparent text-grey-2 hover:text-black hover:bg-grey-11 focus:outline-none">
        <i class="ss-gizmo ss-hyphen" />
      </button>
      <button @click.prevent="incrementEvent" class="lg:inline-block border border-black border-solid m-0 bg-transparent text-grey-2 hover:text-black hover:bg-grey-11 focus:outline-none">
        <i class="ss-gizmo ss-plus" />
      </button>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages'
import debounce from 'lodash-es/debounce'

export default {
  name: 'BaseInputNumber',
  components: {
    ValidationMessages
  },
  data () {
    return {
    }
  },
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    increment: {
      type: Number,
      default: 1
    },
    classes: {
      type: [String, Object],
      default: '',
      required: false
    }
  },
  computed: {
    getInputId () {
      return `input_${this._uid}`
    },
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        const value = this.round(this.value)

        this.$emit('input', value)
      }
    }
  },
  methods: {
    keyUpEvent ($event) {
      this.inputEvent($event)
    },
    keyupEnter ($event) {
      this.inputEvent($event)
    },
    inputEvent: debounce(function ($event) {
      let value = parseInt($event.target.value, 10)

      if (isNaN(value)) {
        value = this.increment
      }

      value = this.round(value)

      this.$emit('input', value)
    }, 1000),
    blurEvent ($event) {
      let value = parseInt($event.target.value)

      if (isNaN(value)) {
        value = this.increment
      }
      this.$emit('blur', value)
    },
    incrementEvent ($event) {
      let value = this.value

      value += this.increment

      this.$emit('input', value)
    },
    decrementEvent ($event) {
      let value = this.value

      value -= this.increment

      this.$emit('input', value)
    },
    round (val) {
      val = parseInt(val, 10)
      if (val < this.increment) {
        return this.increment
      }
      return Math.round(val / this.increment) * this.increment
    }
  }
}
</script>

<style lang="scss" scoped>
  .quantity-select {
    max-width: inherit;

    .quantity-select-action {
      padding: 12px .98rem 13px;
    }
  }

  button {
    border-left-width: 0;
    height: 48px;
    width: 48px;
  }

  input:disabled {
    background-color: #bdbdbd;
  }

  input[type='number'] {
    width: calc(100% - 96px);
    min-width: 30px;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
</style>
