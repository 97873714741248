<template>
  <div class="product-listing">
    <h3 v-if="heading" class="product-listing__heading whitespace-pre-line text-heading-page" :class="`text-${color}`">
      {{ heading }}
    </h3>
    <div class="flex flex-wrap justify-start mt-7 overflow-hidden">
      <div
        class="lg:product-listing__item lg:justify-start lg:items-start pr-xl w-full lg:w-1/4 relative lg:flex"
        :class="[`lg:w-1/${columns}`]"
      >
        <slot name="callout" />
      </div>
      <div
        v-for="product in products"
        :key="product.id"
        class="product-listing__item w-1/2 relative"
        :class="[`lg:w-1/${columns}`]"
      >
        <product-tile
          :product="product"
          class="pt-5"
          :product-id="product.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductTile from './ProductTile'

export default {
  name: 'ProductListing',
  components: {
    ProductTile
  },
  props: {
    heading: {
      type: String,
      required: false,
      default: null
    },
    products: {
      type: null,
      required: true
    },
    columns: {
      type: [Number, String],
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'primary'
    }
  },
  computed: {
    hasCallout () {
      return !!this.$slots['callout']
    }
  }
}
</script>

<style lang="scss" scoped>
.product-listing {
  &__heading {
    text-indent: -.75em;
    padding-left: .7em;
  }

  &__subheader {
    &:after {
      @screen lg {
        @apply border-t absolute;
        content: '';
        width: 320px;
        height: 0;
        top: 167px;
        right: -46px;
        transform-origin: center center;
        transform: rotate(-45deg);
      }
    }
  }

  &__item {
    @screen md {
      &:nth-child(odd):before {
        display: block;
        width: 200%;
      }
    }

    @screen lg {
      &:nth-child(4n+1):before {
        display: block;
        width: 400%;
      }
    }
  }
}
</style>
