<template>
  <tr v-if="isLoyaltyItem" class="product-details product-details-loyalty" :class="isInStock ? '' : 'border-4 border-error'">
    <td class="text-center lg:w-24">
      <router-link
        :to="productLink(product)"
      >
        <img
          class="image max-h-thumb-s"
          v-lazy="thumbnail"
          :alt="product.name"
        />
      </router-link>
    </td>
    <td>
      <div class="bg-error text-md text-white font-bold p-1 mb-2 mr-4" v-if="!isInStock">OUT OF STOCK</div>
      <router-link
        :to="productLink(product)"
      >
        <span class="block">
          <span class="block" v-if="configurableProductInfo">{{ configurableProductInfo.label | stripHTML }}: {{ configurableProductInfo.value | stripHTML }}</span>
          <span class="block">{{ product.name | htmlDecode }}</span>
        </span>
        <span v-if="product.sizing_oz" class="block text-h4 text-grey-2 uppercase">
          {{ `${product.sizing_oz} Oz` }}
        </span>
      </router-link>
    </td>
    <td>
      <div class="product-details__price">
        <div class="prices">
          <span>&diams; </span>
          <span class="" v-if="!rulePrice">
              {{(product.point_redemption_price || 0).toFixed(2) }}
            </span>
          <span class="" v-else>
              {{ rulePrice }}
            </span>
          <span>{{ $t('Pts') }}</span>
        </div>
      </div>
    </td>
    <td>
      <base-quantity-input
        :min="incrementStep"
        :name="$t('Quantity')"
        :readonly="true"
        @input="updateQuantity"
        :increment="incrementStep"
        :value="rawQty"
        :loading="isLoading"
        :loader-color="`text-black`"
        :classes="{'bg-primary2': isLoading}"
        @remove="removeItem"
      />
    </td>
    <td>
      <div class="product-details__price">
        <div class="prices">
            <span>&diams; </span>
           <span class="" v-if="!rulePrice">
            {{(product.point_redemption_price || 0).toFixed(2) * (getLoyaltyQuantity || 1) }}
          </span>
            <span class="" v-else>
            {{ rulePrice * (getLoyaltyQuantity || 1) }}
          </span>
            <span>{{ $t('Pts') }}</span>
        </div>
      </div>

    </td>
  </tr>
</template>

<script>
import Product from 'theme/mixins/blocks/Product'
import BaseQuantityInput from 'theme/components/core/blocks/Form/BaseQuantityInput'
import PriceRules from 'theme/mixins/blocks/PriceRules'
// import Loader from 'theme/components/theme/Loader'

export default {
  name: 'ProductDetailsLoyalty',
  components: {
    BaseQuantityInput
    // Loader
  },
  mixins: [
    Product,
    PriceRules
  ],
  data () {
    return {
      isLoyalty: true
    }
  }
}
</script>

<style lang="scss">
  .product-details {
    &__price {
      .prices {
        span {
          @apply font-serif italic text-heading-page leading-h5;
        }
      }
    }

    .quantity-select-action {
      max-width: 110px;

      input[type='number'] {
        @apply border-grey-16;
        padding: 0;
        text-align: center;
      }

      button {
        border: none;
        height: 26px;
        margin: 0 2px;
        padding-top: 6px;
        width: 26px;
      }
    }
  }
</style>
