<template>
  <modal name="modal-points-quick-view" class="modal" :width="660">
    <div slot="header">
      <div class="text-center uppercase tracking-xl leading-h2 text-h5 font-bold">
        {{ $t('Redeem points') }}
      </div>
    </div>
    <div slot="content">
      <div class="popup-title font-black leading-none text-center text-black font-serif">
        {{ product.name | htmlDecode }}
      </div>
      <div class="popup-content">
        <div class="pro_grid_pd relative" :class="'product-' + productBank.code">
          <img
            class="image block m-auto"
            :alt="product.name"
            :src="thumbnailObj.src"
            v-lazy="thumbnailObj"
          >
          <div class="pro_grid_mshort" v-html="product.description" />
          <div class="pro_grid_mprice text-lg leading-none text-dark relative pt-7">
            <span class="pro_grid_title absolute block text-h6 leading-none tracking-thick uppercase">
              <span>{{ productBank.name }}</span>
              <span>{{ $t('Points price') }}</span>
            </span>
            <div class="pts_price inline-block text-dark-2 brand-bg relative text-center inline-block p-0 w-full">
              <span class="pts_pts font-black text-h5 tracking-md pr-xs">
                {{ totalPointsPrice }}
              </span>
              <span class="pts_brand font-black text-h5 tracking-md uppercase relative">
                {{ $t('Loyalty Points') }}
              </span>
            </div>
          </div>
          <template v-if="isInStock">
            <div class="w-full mb-xs" v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'">
              <base-input-number
                :min="1"
                :name="$t('Quantity')"
                :class="'quantity-select'"
                :increment="incrementStep"
                @blur="$v.$touch()" :validations="[
                {
                  condition: $v.product.qty.$error && !$v.product.qty.minValue,
                  text: $t('Quantity must be above 0')
                },
                {
                  condition: $v.product.qty.isValueMultiple && incrementStep !== 1,
                  text: $t('Quantity must be multiple of ') + incrementStep
                }
              ]"
                v-model="product.qty">{{ $t('Qty') }}</base-input-number>
            </div>
            <p class="text-average leading-loose pb-6 text-grey-23 tracking-sm">
              {{ $t('Available for purchase in increments of ') + incrementStep }}
            </p>
            <button-with-icon
              @click.native="addToRewardsCart"
              :disabled="!isInStock || loading || OfflineOnly"
              :loading="loading"
              class="popup-button flex-grow pr-5 pl-10 w-full"
            >
              <span v-if="OnlineOnly">
                {{ $t('Buy with') }} {{ totalPointsPrice }} {{ $t('points') }}
              </span>
              <span v-else>
                {{ $t('You are offline.') }}
              </span>
            </button-with-icon>
          </template>
          <div v-else class="back-in-stock">
            <p class="text-base font-bold text-primary text-center tracking-md uppercase mt-4 mb-2">
              {{ $t('Out of stock') }}
            </p>
            <back-in-stock-form :product="product" @success="onSuccess" @error="onError" />
          </div>
          </div>
          <div class="pro_available_pts text-h6 leading-loose tracking-thick text-dark uppercase">
            <span class="block ">{{ $t('You Currently Have') }}</span>
            <span class="pro_total_pts_avail brand-text font-black text-h6 leading-loose">
              <span class="points">{{ fullBalance }}</span>
              {{ productBank.name }} {{ $t('Loyalty Points') }}
            </span>
            <span class="pro_total_pts_avail_label uppercase font-black text-h6 leading-loose tracking-thick">
              {{ $t('Available') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { minValue } from 'vuelidate/lib/validators'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import Modal from 'theme/components/core/Modal'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import BaseInputNumber from 'theme/components/core/blocks/Product/BaseInputNumber'
import BackInStockForm from 'theme/components/theme/BackInStockForm'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import { getProductQtyIncrement } from 'theme/helpers/getProductQtyIncrement'
import i18n from '@vue-storefront/i18n'
import { mapGetters } from 'vuex'
import VueOfflineMixin from 'vue-offline/mixin'
import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'

const isValueMultiple = (step) => (value) => value % step !== 0

export default {
  name: 'RedeemPointsQuickView',
  mixins: [
    ProductTile,
    PriceRules,
    VueOfflineMixin,
    EarnedPoints
  ],
  components: {
    Modal,
    ButtonWithIcon,
    BaseInputNumber,
    BackInStockForm
  },
  props: {
    brands: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      incrementStep: 1,
      rulePrice: null,
      loading: false
    }
  },
  watch: {
    product: function (prod) {
      this.incrementStep = getProductQtyIncrement(prod)
    }
  },
  computed: {
    ...mapGetters({
      cartItems: 'cart/getCartItems',
    }),
    totalPointsPrice () {
      let rulePrice = this.getRulePrice(this.product)
      let price = (this.product.point_redemption_price * (this.product.qty ? this.product.qty : this.incrementStep)).toFixed(2)

      if (rulePrice) price = (rulePrice * (this.product.qty ? this.product.qty : this.incrementStep)).toFixed(2)
      return price
    },
    customerAccounts () {
      return this.$store.getters['loyalty/getCustomerAccounts']
    },
    productBank () {
      for (const key in this.brands) {
        if (this.brands.hasOwnProperty(key)) {
          const bank = this.brands[key]

          if (bank.code === this.getBrandCode(this.product.brand_id)) {
            return bank
          }
        }
      }
      return {}
    },
    fullBalance () {
      return this.earnedBalance - this.spentBalance
    },
    earnedBalance () {
      if (this.productBank && this.productBank.earnedPoints) {
        return (this.productBank.balance + this.productBank.earnedPoints).toFixed(2)
      }

      if (this.productBank) {
        return parseFloat(this.productBank.balance || 0).toFixed(2)
      }

      return (0).toFixed(2)
    },
    spentBalance () {
      let brand = this.productBank
      let balance = 0
      this.cartItems.filter(x => x.brand_id === brand.id).forEach(item => {
        let rewardData = ((item.extension_attributes || {}).reward_data || {})

        let qty = rewardData.quantity || 0
        let price = rewardData.price || 0
        balance += price * qty
      })
      return balance
    },
    isInStock () {
      return this.product.stock ? this.product.stock.is_in_stock : false
    }
  },
  async mounted () {
    this.incrementStep = getProductQtyIncrement(this.product)
  },
  methods: {
    notifyUser(notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData)
    },
    async addToRewardsCart () {
      this.loading = true

      try {
        const diffLog = await this.$store.dispatch('cart/upsertLoyaltyItem', {
          product: this.product,
          qty: this.product.qty,
          upsert: false // we want to update on old qty
        })

        if (diffLog) {
          let hasError = false

          if (diffLog.clientNotifications && diffLog.clientNotifications.length > 0) {
            diffLog.clientNotifications.forEach(notificationData => {
              if (notificationData.type === 'error') {
                hasError = true
              }
              this.notifyUser( {
                type: notificationData.type,
                message: notificationData.message,
                action1: { label: i18n.t('OK') },
                action2: null
              })
            })
          } else if (diffLog.serverResponses && diffLog.serverResponses.length > 0) {
            diffLog.serverResponses.forEach(notificationData => {
              if (notificationData.result.resultCode === 400) {
                hasError = true
                this.notifyUser({
                  type: 'error',
                  message: notificationData.result.result,
                  action1: {label: i18n.t('OK')},
                  action2: null
                })
              }
            })
          }

          if (!hasError) {
            // this.$store.dispatch('loyalty/addRewardItem', { productToAdd: this.product })
            this.$bus.$emit('modal-hide', 'modal-points-quick-view')
          }
        } else {
          this.notifyUser({
            type: 'success',
            message: i18n.$t('Product has been added to the cart!'),
            action1: { label: i18n.t('OK') },
            action2: null
          })
          // this.$store.dispatch('loyalty/addRewardItem', { productToAdd: this.product })
          this.$bus.$emit('modal-hide', 'modal-points-quick-view')
        }
        this.loading = false
        return diffLog
      } catch (err) {
        this.notifyUser({
          type: 'error',
          message: err || 'An error occurred....',
          action1: { label: i18n.t('OK') }
        })
        this.loading = false
        return null
      }
    },
    onSuccess () {
      this.notifyUser( {
        type: 'success',
        message: i18n.t('You will be notified as soon as product will be available.'),
        action1: { label: i18n.t('OK') }
      })
    },
    onError () {
      this.notifyUser( {
        type: 'error',
        message: i18n.t('Could not add product to watching list'),
        action1: { label: i18n.t('OK') }
      })
    }
  },
  validations () {
    return {
      product: {
        qty: {
          minValue: minValue(1),
          isValueMultiple: isValueMultiple(this.incrementStep)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$rco-color: #00e1ba;
$sc-color: #f84876;
$v76-color: #f3f281;
$elz-color: #cf9a7e;

.product-rco {
  .brand-bg {
    background-color: $rco-color;
  }

  .brand-text {
    color: $rco-color;
  }
}

.product-sc {
  .brand-bg {
    background-color: $sc-color;
  }

  .brand-text {
    color: $sc-color;
  }
}

.product-v76 {
  .brand-bg {
    background-color: $v76-color;
  }

  .brand-text {
    color: $v76-color;
  }
}

.product-elz {
  .brand-bg {
    background-color: $elz-color;
  }

  .brand-text {
    color: $elz-color;
  }
}

.popup-title {
  font-size: 38px;
  line-height: 40px;
  margin-bottom: 30px;
}

.popup-button {
  @apply inline-flex justify-between items-center;
  padding-bottom: 18px;
  padding-top: 18px;

  &::v-deep {
    span {
      @apply flex justify-between items-center;
    }
  }
}

.pro_grid_pd {
  margin-bottom: 25px;
  min-height: 320px;
  @screen lg {
    padding-left: 330px;
  }


  .image {
    @screen lg {
      top: 0;
      left: 55px;
      position: absolute;
    }
  }

  .pro_grid_mshort {
    margin-bottom: 18px;
  }

  .pro_grid_mprice {
    margin-bottom: 40px;
    height: 32px;
  }

  .pro_grid_title {
    top: 0;
    left: 3px;
  }

  .pts_price {
    height: 36px;
  }

  .pts_price,
  .pts_brand {
    line-height: 32px;
  }

  .pro_available_pts {
    margin-top: 18px;
    margin-left: 3px;
  }

  .pro_total_pts_avail {

  }

  .pro_total_pts_avail_label {
    margin-left: 2px;
  }
}

.back-in-stock {
  &::v-deep {
    .back-in-stock-form {
      & > button {
        @apply w-full;
      }
    }
  }
}
</style>
