<template>
  <div class="loyalty-cart bg-white">
    <template v-if="productsInCart.length">
      <div v-for="(item, index) in productsInCartByBrands" :key="index">
        <span class="px-5 py-3 block bg-black text-white font-bold text-h5 leading-none tracking-average uppercase">
          {{ item.brand.label }}
        </span>
        <ul class="pro_cart_list relative">
          <product v-for="product in item.products" :key="product.id" :product="product" />
        </ul>
      </div>
    </template>
    <div v-else class="px-5 py-4 font-semibold text-h3">{{ $t('No products selected.') }}</div>
  </div>
</template>

<script>
import Product from './CartProduct'

export default {
  name: 'LoyaltyCart',
  components: {
    Product
  },
  data () {
    return {
      brands: [
        {
          id: 1,
          categoryId: 41,
          label: 'R+Co',
          code: 'rco'
        },
        {
          id: 2,
          categoryId: 40,
          label: 'V76 by Vaughn',
          code: 'v76'
        },
        {
          id: 3,
          categoryId: 42,
          label: 'Smith & Cult',
          code: 'sc'
        },
        {
          id: 5,
          categoryId: 42,
          label: 'R+Co Bleu',
          code: 'rco_bleu'
        },
        {
          id: 6,
          categoryId: 42,
          label: 'R+Color',
          code: 'rco_color'
        }
      ]
    }
  },
  computed: {
    productsInCart () {
      return this.$store.getters['cart/getLoyaltyCartItems']
    },
    productsInCartByBrands () {
      if (this.productsInCart.length < 1) {
        return []
      }

      let productsByBrands = []

      for (let i = 0; i < this.brands.length; i++) {
        const brand = this.brands[i]
        const products = this.productsInCart.filter(product => {
          return product.hasOwnProperty('brand_id') && product.brand_id === brand.id
        })

        if (products.length > 0) {
          productsByBrands.push({
            brand,
            products
          })
        }
      }

      return productsByBrands
    }
  }
}
</script>

<style lang="scss" scoped>
.loyalty-cart {
  background-color: #fff;
  background-image: url('/assets/pattern-tile.png');
  background-repeat: repeat;
  background-position: left top;
}
</style>
