<template>
  <div
    class="points-rewards"
    :style="{ borderLeftColor: `#${reward.color}` }"
  >
    <div class="reward-block">
      <span
        class="reward-amount pro-point-value relative"
        @mouseover="isVisible = !isVisible"
        @mouseleave="isVisible = false"
      >
        {{ getEarnedBalance }}
        <span v-if="reward.increase">
          <span
            class="loyalty-points__increase"
            :style="{ borderBottomColor: `#${reward.color}` }"
          />
          <div
            class="loyalty-points__increase-tooltip hidden text-left md:block"
            :class="{ 'loyalty-points__increase-tooltip--visible' : isVisible }"
          >
            <p class="loyalty-points__increase-earned text-base font-bold uppercase">
              {{ `+${reward.earnedPoints.toFixed(2)} ` + $t('Points') }}
            </p>
            <p v-html="tooltipText" />
          </div>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
  import i18n from '@vue-storefront/i18n'

  export default {
    name: 'EarnedPointsBalance',
    props: {
      reward: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        isVisible: this.showTooltip,
        tooltipText: i18n.t('Your total <span class="font-bold">Loyalty Points Point</span> earnings')
      }
    },
    computed: {
      getEarnedBalance () {
        return this.reward.earnedPoints
          ? (this.reward.balance + this.reward.earnedPoints).toFixed(2)
          : parseFloat(this.reward.balance || 0).toFixed(2)
      }
    }
  }
</script>

<style lang="scss" scoped>
  $color-green: #00e1ba;
  $color-yellow: #f3f281;
  $color-red: #f84876;
  $color-white: #ffffff;

  .loyalty-points {
    &__increase {
      @apply absolute block border-l-5 border-r-5 border-b-5 border-solid border-transparent z-10;
      right: -30px;
      top: 50%;
      transform: translate(0, -50%);

      @screen md {
        right: 6px;
      }
    }

    &__increase-tooltip {
      @apply absolute bg-white shadow-secondary text-black p-3 opacity-0 pointer-events-none z-10;
      bottom: 15px;
      right: -190px;
      text-indent: 0;
      transform: translateY(-20px);
      transition: all 0.3s ease;
      width: 200px;

      &--visible {
        @apply opacity-100;
        transform: translateY(0);
        transition: all 0.3s ease;
      }
    }
  }
</style>
