<template>
  <div class="relative" v-if="renderComponent">
    <label
      v-if="hasDefaultSlot"
      :for="getInputId"
      class="label uppercase text-black text-h6 tracking-md leading-thick whitespace-no-wrap"
    >
      <slot />
    </label>
    <div class="inline-flex items-center justify-center quantity-select-action w-full actions">
      <div>

      </div>
        <input
          v-show="!loading"
          type="number"
          :disabled="!editing || disabled"
          :name="name"
          :min="min"
          class="w-1/3 inline-block text-dark py-sm px-5 focus:outline-none focus:bg-grey-11 border border-solid border-dark text-average leading-sm tracking-xs"
          :class="classes"
          :focus="autofocus"
          @focus="$emit('focus')"
          @keyup.enter="approve"
          @keyup.esc="deny"
          @keyup.up.stop.prevent="valueIncrement"
          @keyup.right.stop.prevent="valueIncrement"
          @keyup.down.stop.prevent="valueDecrement"
          @keyup.left.stop.prevent="valueDecrement"
          @blur.native="valueRound"
          @focusout.native="valueRound"
          :step="increment"
          :value="rawValue"
          ref="numberInput"
        >
        <loader class="inline-block" :color="loaderColor" aria-label="Loading" v-show="loading" />

        <button v-if="!editing" @click.prevent="edit" class="w-1/3 bg-primary2 font-bold text-white" :disabled="disabled" aria-live="assertive">
          <i v-if="!disabled" class="ss-gizmo ss-write"><span class="sr-only">Edit</span></i>
          <i v-else class="ss-gizmo ss-writingdisabled"><span class="sr-only">Editing Disabled</span></i>
        </button>
        <button v-if="!editing" class="w-1/3 bg-red font-bold text-white" @click="remove">
          <i class="ss-gizmo ss-trash" />
        </button>
        <button v-if="editing" class="w-1/3 bg-green font-bold text-white" @click="approve">
          <i class="ss-gizmo ss-check" />
        </button>
        <button v-if="editing" class="w-1/3 bg-error font-bold text-white" @click="deny">
          <i class="ss-gizmo ss-delete" />
        </button>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
    <div v-if="editing" class="label uppercase text-h6 tracking-md leading-thick whitespace-no-wrap text-red-2 block mb-1 my-2 text-sm">
      <strong class="w-full px-5" aria-label="Keyboard Controls">
        <span class="keyboard text-black" title="Use Up/Down Arrows to Adjust Quantity">&#8593; &#8595;</span>
        <span class="keyboard text-green" title="Press Enter to Submit Changes">↵</span>
        <span class="keyboard text-error" title="Press Escape to Cancel">Esc</span>
      </strong>
    </div>
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages'
import Loader from 'theme/components/theme/Loader'

export default {
  name: 'BaseQuantityInput',
  components: {
    ValidationMessages,
    Loader
  },
  data () {
    return {
      renderComponent: true,
      editing: false,
      rawValue: 0
    }
  },
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    increment: {
      type: Number,
      default: 1
    },
    classes: {
      type: [String, Object],
      default: '',
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    loaderColor: {
      type: String,
      default: 'text-black'
    }
  },
  watch: {
    value: function (val) {
      this.rawValue = val
    }
  },
  mounted () {
    this.rawValue = this.value
  },
  computed: {
    getInputId () {
      return `input_${this._uid}`
    },
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  },
  methods: {
    forceRerender () {
      // Removing my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
    },
    edit () {
      this.editing = true
      this.$nextTick(() => {
        this.$refs.numberInput.focus()
      })
      this.$bus.$emit('cart-ui-action')
    },

    remove () {
      this.$emit('remove', true)
      this.editing = false
    },
    approve (event) {
      this.$nextTick(() => {
        let value = parseInt(event.target.value || this.$refs.numberInput.value, 10)

        if (isNaN(value)) {
          value = this.increment
        }

        value = this.round(value)

        this.rawValue = value

        this.$emit('input', value)

        this.editing = false
      })
    },
    deny () {
      this.$nextTick(() => {
        this.rawValue = this.value
        this.editing = false
      })
    },
    round (val) {
      val = parseInt(val, 10)
      if (val < this.increment) {
        return this.increment
      }
      return Math.round(val / this.increment) * this.increment
    },
    valueRound () {
      this.rawValue = this.round(this.rawValue)
    },
    valueIncrement () {
      this.$nextTick(() => {
        this.rawValue += this.increment
        this.rawValue = this.round(this.rawValue)
      })
    },
    valueDecrement () {
      this.$nextTick(() => {
        this.rawValue -= this.increment

        if (this.rawValue <= 0) {
          this.rawValue = this.increment
          return
        }

        this.rawValue = this.round(this.rawValue)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .keyboard {
     width: 30px;
  }
  .quantity-select {
    max-width: inherit;

    .quantity-select-action {
      padding: 12px .98rem 13px;
    }
  }

  .actions button {
    aspect-ratio: 1 / 1;
    height: 100%;
    &:disabled {
      @apply bg-grey-1;
      cursor: not-allowed;
    }
  }

  //button {
  //  border-left-width: 0;
  //  height: 48px;
  //  width: 48px;
  //}

  input:disabled, input[disabled] {
    background-color: #bdbdbd;
    cursor: not-allowed;
  }

  input[type='number'] {
    width: calc(100% - 96px);
    min-width: 30px;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating:before {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
</style>
